//
// GENERATED SOURCE - DO NOT EDIT
//

import { useMemo } from 'react';
import { RestClient } from '@kapeta/sdk-web-rest-client';
import { RestClientRequest } from '@kapeta/sdk-rest';
import { Invitation } from '../../../.generated/entities/Invitation';

export type ConfigureInvitationsClient = (client: InvitationsClient) => InvitationsClient;

/**
 * Creates a new InvitationsClient for React components.
 * The client is configured with the provided configuration function.
 *
 * It is wrapped in a useMemo hook to ensure that the client is only created once.
 */
export const useInvitationsClient = (configure?: ConfigureInvitationsClient): InvitationsClient => {
    return useMemo(() => {
        const client = new InvitationsClient();
        if (configure) {
            return configure(client);
        }
        return client;
    }, [configure]);
};

/**
 * A client for the Invitations API.
 * Use the useInvitationsClient hook to create a client in React.
 *
 * Use the InvitationsClient directly in other contexts.
 */
export class InvitationsClient extends RestClient {
    constructor() {
        super('api/rest/invitations');
    }

    /**
     * HTTP: POST /api/rest/invitations/invitations/{invitationId}/revoke
     */
    async revokeInvitation(invitationId: string): Promise<void> {
        await this.$execute<void>('POST', '/invitations/{invitationId}/revoke', [
            { name: 'invitationId', value: invitationId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/invitations/invitations/{invitationId}/revoke
     */
    revokeInvitationRequest(invitationId: string): RestClientRequest<void> {
        return this.$create<void>('POST', '/invitations/{invitationId}/revoke', [
            { name: 'invitationId', value: invitationId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: POST /api/rest/invitations/invitations/{invitationId}/resend
     */
    async resendInvitation(invitationId: string): Promise<void> {
        await this.$execute<void>('POST', '/invitations/{invitationId}/resend', [
            { name: 'invitationId', value: invitationId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/invitations/invitations/{invitationId}/resend
     */
    resendInvitationRequest(invitationId: string): RestClientRequest<void> {
        return this.$create<void>('POST', '/invitations/{invitationId}/resend', [
            { name: 'invitationId', value: invitationId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: POST /api/rest/invitations/invitations/{invitationId}/reject
     */
    async rejectInvitation(invitationId: string): Promise<void> {
        await this.$execute<void>('POST', '/invitations/{invitationId}/reject', [
            { name: 'invitationId', value: invitationId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/invitations/invitations/{invitationId}/reject
     */
    rejectInvitationRequest(invitationId: string): RestClientRequest<void> {
        return this.$create<void>('POST', '/invitations/{invitationId}/reject', [
            { name: 'invitationId', value: invitationId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: POST /api/rest/invitations/invitations/{invitationId}/accept
     */
    async acceptInvitation(invitationId: string): Promise<void> {
        await this.$execute<void>('POST', '/invitations/{invitationId}/accept', [
            { name: 'invitationId', value: invitationId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/invitations/invitations/{invitationId}/accept
     */
    acceptInvitationRequest(invitationId: string): RestClientRequest<void> {
        return this.$create<void>('POST', '/invitations/{invitationId}/accept', [
            { name: 'invitationId', value: invitationId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/invitations/invitations/{invitationId}
     */
    async getInvitation(invitationId: string): Promise<Invitation | null> {
        const result = await this.$execute<Invitation>('GET', '/invitations/{invitationId}', [
            { name: 'invitationId', value: invitationId, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as Invitation;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/invitations/invitations/{invitationId}
     */
    getInvitationRequest(invitationId: string): RestClientRequest<Invitation | null> {
        return this.$create<Invitation>('GET', '/invitations/{invitationId}', [
            { name: 'invitationId', value: invitationId, transport: 'PATH', typeName: 'string' },
        ]);
    }
}
