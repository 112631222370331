//
// GENERATED SOURCE - DO NOT EDIT
//

import { useMemo } from 'react';
import { RestClient } from '@kapeta/sdk-web-rest-client';
import { RestClientRequest } from '@kapeta/sdk-rest';
import { IdentityIdentifier } from '../../../.generated/entities/IdentityIdentifier';
import { ValueBody } from '../../../.generated/entities/ValueBody';
import { CreateIdentifierRequest } from '../../../.generated/entities/CreateIdentifierRequest';
import { IdentifierPublic } from '../../../.generated/entities/IdentifierPublic';

export type ConfigureIdentityIdentifierClient = (client: IdentityIdentifierClient) => IdentityIdentifierClient;

/**
 * Creates a new IdentityIdentifierClient for React components.
 * The client is configured with the provided configuration function.
 *
 * It is wrapped in a useMemo hook to ensure that the client is only created once.
 */
export const useIdentityIdentifierClient = (
    configure?: ConfigureIdentityIdentifierClient
): IdentityIdentifierClient => {
    return useMemo(() => {
        const client = new IdentityIdentifierClient();
        if (configure) {
            return configure(client);
        }
        return client;
    }, [configure]);
};

/**
 * A client for the IdentityIdentifier API.
 * Use the useIdentityIdentifierClient hook to create a client in React.
 *
 * Use the IdentityIdentifierClient directly in other contexts.
 */
export class IdentityIdentifierClient extends RestClient {
    constructor() {
        super('api/rest/identityIdentifier');
    }

    /**
     * HTTP: PUT /api/rest/identityidentifier/identities/{identityId}/identifiers/{identifierId}
     */
    async updateIdentifier(
        identityId: string,
        identifierId: string,
        valueBody: ValueBody
    ): Promise<IdentityIdentifier | null> {
        const result = await this.$execute<IdentityIdentifier>(
            'PUT',
            '/identities/{identityId}/identifiers/{identifierId}',
            [
                { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
                { name: 'identifierId', value: identifierId, transport: 'PATH', typeName: 'string' },
                { name: 'valueBody', value: valueBody, transport: 'BODY', typeName: 'ValueBody' },
            ]
        );

        if (result === null) {
            return null;
        }
        return result as IdentityIdentifier;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: PUT /api/rest/identityidentifier/identities/{identityId}/identifiers/{identifierId}
     */
    updateIdentifierRequest(
        identityId: string,
        identifierId: string,
        valueBody: ValueBody
    ): RestClientRequest<IdentityIdentifier | null> {
        return this.$create<IdentityIdentifier>('PUT', '/identities/{identityId}/identifiers/{identifierId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'identifierId', value: identifierId, transport: 'PATH', typeName: 'string' },
            { name: 'valueBody', value: valueBody, transport: 'BODY', typeName: 'ValueBody' },
        ]);
    }

    /**
     * HTTP: DELETE /api/rest/identityidentifier/identities/{identityId}/identifiers/{identifierId}
     */
    async removeIdentifier(identityId: string, identifierId: string): Promise<void> {
        await this.$execute<void>('DELETE', '/identities/{identityId}/identifiers/{identifierId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'identifierId', value: identifierId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: DELETE /api/rest/identityidentifier/identities/{identityId}/identifiers/{identifierId}
     */
    removeIdentifierRequest(identityId: string, identifierId: string): RestClientRequest<void> {
        return this.$create<void>('DELETE', '/identities/{identityId}/identifiers/{identifierId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'identifierId', value: identifierId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: PUT /api/rest/identityidentifier/identities/{identityId}/identifiers/{identifierId}/verification
     */
    async verifyIdentifier(identityId: string, identifierId: string, valueBody: ValueBody): Promise<void> {
        await this.$execute<void>('PUT', '/identities/{identityId}/identifiers/{identifierId}/verification', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'identifierId', value: identifierId, transport: 'PATH', typeName: 'string' },
            { name: 'valueBody', value: valueBody, transport: 'BODY', typeName: 'ValueBody' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: PUT /api/rest/identityidentifier/identities/{identityId}/identifiers/{identifierId}/verification
     */
    verifyIdentifierRequest(identityId: string, identifierId: string, valueBody: ValueBody): RestClientRequest<void> {
        return this.$create<void>('PUT', '/identities/{identityId}/identifiers/{identifierId}/verification', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'identifierId', value: identifierId, transport: 'PATH', typeName: 'string' },
            { name: 'valueBody', value: valueBody, transport: 'BODY', typeName: 'ValueBody' },
        ]);
    }

    /**
     * HTTP: PUT /api/rest/identityidentifier/identities/{identityId}/identifiers/{identifierId}/verification/resend
     */
    async resendVerification(identityId: string, identifierId: string): Promise<void> {
        await this.$execute<void>('PUT', '/identities/{identityId}/identifiers/{identifierId}/verification/resend', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'identifierId', value: identifierId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: PUT /api/rest/identityidentifier/identities/{identityId}/identifiers/{identifierId}/verification/resend
     */
    resendVerificationRequest(identityId: string, identifierId: string): RestClientRequest<void> {
        return this.$create<void>('PUT', '/identities/{identityId}/identifiers/{identifierId}/verification/resend', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'identifierId', value: identifierId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: POST /api/rest/identityidentifier/identities/{identityId}/identifiers/register
     */
    async registerIdentifier(
        identityId: string,
        createIdentifierRequest: CreateIdentifierRequest
    ): Promise<IdentityIdentifier | null> {
        const result = await this.$execute<IdentityIdentifier>(
            'POST',
            '/identities/{identityId}/identifiers/register',
            [
                { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
                {
                    name: 'createIdentifierRequest',
                    value: createIdentifierRequest,
                    transport: 'BODY',
                    typeName: 'CreateIdentifierRequest',
                },
            ]
        );

        if (result === null) {
            return null;
        }
        return result as IdentityIdentifier;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/identityidentifier/identities/{identityId}/identifiers/register
     */
    registerIdentifierRequest(
        identityId: string,
        createIdentifierRequest: CreateIdentifierRequest
    ): RestClientRequest<IdentityIdentifier | null> {
        return this.$create<IdentityIdentifier>('POST', '/identities/{identityId}/identifiers/register', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            {
                name: 'createIdentifierRequest',
                value: createIdentifierRequest,
                transport: 'BODY',
                typeName: 'CreateIdentifierRequest',
            },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identityidentifier/identities/{identityId}/identifiers
     */
    async list(identityId: string, type?: string): Promise<IdentityIdentifier[] | null> {
        const result = await this.$execute<IdentityIdentifier[]>('GET', '/identities/{identityId}/identifiers', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'type', value: type, transport: 'QUERY', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as IdentityIdentifier[];
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identityidentifier/identities/{identityId}/identifiers
     */
    listRequest(identityId: string, type?: string): RestClientRequest<IdentityIdentifier[] | null> {
        return this.$create<IdentityIdentifier[]>('GET', '/identities/{identityId}/identifiers', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'type', value: type, transport: 'QUERY', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identityidentifier/identities/identifiers/{type}/{identifier}/exists
     */
    async identifierExists(type: string, identifier: string): Promise<boolean | null> {
        const result = await this.$execute<boolean>('GET', '/identities/identifiers/{type}/{identifier}/exists', [
            { name: 'type', value: type, transport: 'PATH', typeName: 'string' },
            { name: 'identifier', value: identifier, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as boolean;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identityidentifier/identities/identifiers/{type}/{identifier}/exists
     */
    identifierExistsRequest(type: string, identifier: string): RestClientRequest<boolean | null> {
        return this.$create<boolean>('GET', '/identities/identifiers/{type}/{identifier}/exists', [
            { name: 'type', value: type, transport: 'PATH', typeName: 'string' },
            { name: 'identifier', value: identifier, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identityidentifier/identities/identifiers/search
     */
    async identifierSearch(query: string): Promise<IdentifierPublic[] | null> {
        const result = await this.$execute<IdentifierPublic[]>('GET', '/identities/identifiers/search', [
            { name: 'query', value: query, transport: 'QUERY', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as IdentifierPublic[];
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identityidentifier/identities/identifiers/search
     */
    identifierSearchRequest(query: string): RestClientRequest<IdentifierPublic[] | null> {
        return this.$create<IdentifierPublic[]>('GET', '/identities/identifiers/search', [
            { name: 'query', value: query, transport: 'QUERY', typeName: 'string' },
        ]);
    }
}
