import { AuthScope } from '@kapeta/ui-web-components';

export const SCOPE_IDENTITY_WRITE = 'https://auth.kapeta.com/scopes/identity:write';
export const SCOPE_IDENTITY_READ = 'https://auth.kapeta.com/scopes/identity:read';

export const Scopes: AuthScope[] = [
    {
        id: '*',
        name: 'Full access',
        description: 'All permissions that exist in the system and future permissions that may be added.',
    },
    {
        id: SCOPE_IDENTITY_READ,
        name: 'Identity Management Viewer',
        description: 'Read access to the identity management system.',
    },
    {
        id: SCOPE_IDENTITY_WRITE,
        name: 'Identity Management Editor',
        description: 'Write access to the identity management system.',
    },
    {
        id: 'https://auth.kapeta.com/scopes/registry:read',
        name: 'Registry Viewer',
        description: 'Read access to the registry.',
    },
    {
        id: 'https://auth.kapeta.com/scopes/registry:write',
        name: 'Registry Editor',
        description: 'Write access to the registry.',
    },
    {
        id: 'https://auth.kapeta.com/scopes/deployment:read',
        name: 'Deployment Viewer',
        description: 'Read access to the deployment system.',
    },
    {
        id: 'https://auth.kapeta.com/scopes/deployment:write',
        name: 'Deployment Editor',
        description: 'Write access to the deployment system.',
    },
    {
        id: 'https://auth.kapeta.com/scopes/logging:read',
        name: 'Logging Viewer',
        description: 'Read access to the logging system.',
    },
    {
        id: 'https://auth.kapeta.com/scopes/runtime:read',
        name: 'Runtime Statistics Viewer',
        description: 'Read access to the runtime statistics system.',
    },
];
