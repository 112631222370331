//
// GENERATED SOURCE - DO NOT EDIT
//

import { useMemo } from 'react';
import { RestClient } from '@kapeta/sdk-web-rest-client';
import { RestClientRequest } from '@kapeta/sdk-rest';

export type ConfigureServiceAccountClient = (client: ServiceAccountClient) => ServiceAccountClient;

/**
 * Creates a new ServiceAccountClient for React components.
 * The client is configured with the provided configuration function.
 *
 * It is wrapped in a useMemo hook to ensure that the client is only created once.
 */
export const useServiceAccountClient = (configure?: ConfigureServiceAccountClient): ServiceAccountClient => {
    return useMemo(() => {
        const client = new ServiceAccountClient();
        if (configure) {
            return configure(client);
        }
        return client;
    }, [configure]);
};

/**
 * A client for the ServiceAccount API.
 * Use the useServiceAccountClient hook to create a client in React.
 *
 * Use the ServiceAccountClient directly in other contexts.
 */
export class ServiceAccountClient extends RestClient {
    constructor() {
        super('api/rest/serviceAccount');
    }

    /**
     * HTTP: GET /api/rest/serviceaccount/identities/{parentIdentityId}/serviceaccounts/{serviceAccountId}/jwt
     */
    async exportJWT(parentIdentityId: string, serviceAccountId: string): Promise<any | null> {
        const result = await this.$execute<any>(
            'GET',
            '/identities/{parentIdentityId}/serviceaccounts/{serviceAccountId}/jwt',
            [
                { name: 'parentIdentityId', value: parentIdentityId, transport: 'PATH', typeName: 'string' },
                { name: 'serviceAccountId', value: serviceAccountId, transport: 'PATH', typeName: 'string' },
            ]
        );

        if (result === null) {
            return null;
        }
        return result as any;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/serviceaccount/identities/{parentIdentityId}/serviceaccounts/{serviceAccountId}/jwt
     */
    exportJWTRequest(parentIdentityId: string, serviceAccountId: string): RestClientRequest<any | null> {
        return this.$create<any>('GET', '/identities/{parentIdentityId}/serviceaccounts/{serviceAccountId}/jwt', [
            { name: 'parentIdentityId', value: parentIdentityId, transport: 'PATH', typeName: 'string' },
            { name: 'serviceAccountId', value: serviceAccountId, transport: 'PATH', typeName: 'string' },
        ]);
    }
}
