import React, { PropsWithChildren } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';

type TabPageProps = PropsWithChildren<{
    title?: string;
    introduction?: string;
    maxWidth?: number;
}> &
    BoxProps;

export const TabPage = (props: TabPageProps) => {
    const { title, introduction, maxWidth = 800, children, sx, ...boxProps } = props;

    return (
        <Box
            sx={{
                height: '100%',
                overflow: 'auto',
                flex: 1,
                p: 8,
                ...sx,
            }}
            {...boxProps}
        >
            <Box sx={{ maxWidth }}>
                {(title || introduction) && (
                    <Box sx={{ mb: 4 }}>
                        {title && (
                            <Typography variant="h5" component="h2" sx={{ pt: 0, mb: 2 }}>
                                {title}
                            </Typography>
                        )}
                        {introduction && <Typography variant="body1">{introduction}</Typography>}
                    </Box>
                )}
                {children}
            </Box>
        </Box>
    );
};
