import React, { PropsWithChildren } from 'react';
import { Box, SxProps, Tab, TabProps, Tabs, TabsProps } from '@mui/material';
import { Link } from 'react-router-dom';

export type LeftMenuTabsProps = PropsWithChildren<{
    value: TabsProps['value'];
}>;

export const LeftMenuTabs = ({ value, children }: LeftMenuTabsProps) => {
    return (
        <Box
            sx={{
                pt: '60px',
                height: '100%',
                width: '220px',
                borderRadius: 0,
                backgroundColor: '#d9d9d94d',
            }}
        >
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <Tabs value={value} orientation={'vertical'}>
                {children}
            </Tabs>
        </Box>
    );
};

export type LeftMenuTabProps = Omit<TabProps<typeof Link>, 'sx' | 'component'>;

export const LeftMenuTab = ({ ...props }: LeftMenuTabProps) => {
    return (
        <Tab
            component={Link}
            sx={{
                textTransform: 'unset',
                alignItems: 'flex-end',
                minHeight: 'unset',
            }}
            {...props}
        />
    );
};
