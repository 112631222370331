//
// GENERATED SOURCE - DO NOT EDIT
//

import { useMemo } from 'react';
import { RestClient } from '@kapeta/sdk-web-rest-client';
import { RestClientRequest } from '@kapeta/sdk-rest';
import { MaxDeploymentsPayload } from '../../../.generated/entities/MaxDeploymentsPayload';

export type ConfigureDeploymentConfigClient = (client: DeploymentConfigClient) => DeploymentConfigClient;

/**
 * Creates a new DeploymentConfigClient for React components.
 * The client is configured with the provided configuration function.
 *
 * It is wrapped in a useMemo hook to ensure that the client is only created once.
 */
export const useDeploymentConfigClient = (configure?: ConfigureDeploymentConfigClient): DeploymentConfigClient => {
    return useMemo(() => {
        const client = new DeploymentConfigClient();
        if (configure) {
            return configure(client);
        }
        return client;
    }, [configure]);
};

/**
 * A client for the DeploymentConfig API.
 * Use the useDeploymentConfigClient hook to create a client in React.
 *
 * Use the DeploymentConfigClient directly in other contexts.
 */
export class DeploymentConfigClient extends RestClient {
    constructor() {
        super('api/rest/deploymentConfig');
    }

    /**
     * HTTP: POST /api/rest/deploymentconfig/v1/config/{handle}/max/{maxdeployment}
     */
    async setMaxdeployment(handle: string, maxdeployment: number): Promise<void> {
        await this.$execute<void>('POST', '/v1/config/{handle}/max/{maxdeployment}', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
            { name: 'maxdeployment', value: maxdeployment, transport: 'PATH', typeName: 'number' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/deploymentconfig/v1/config/{handle}/max/{maxdeployment}
     */
    setMaxdeploymentRequest(handle: string, maxdeployment: number): RestClientRequest<void> {
        return this.$create<void>('POST', '/v1/config/{handle}/max/{maxdeployment}', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
            { name: 'maxdeployment', value: maxdeployment, transport: 'PATH', typeName: 'number' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/deploymentconfig/v1/config/{handle}/max
     */
    async getMaxdeployment(handle: string): Promise<MaxDeploymentsPayload | null> {
        const result = await this.$execute<MaxDeploymentsPayload>('GET', '/v1/config/{handle}/max', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as MaxDeploymentsPayload;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/deploymentconfig/v1/config/{handle}/max
     */
    getMaxdeploymentRequest(handle: string): RestClientRequest<MaxDeploymentsPayload | null> {
        return this.$create<MaxDeploymentsPayload>('GET', '/v1/config/{handle}/max', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);
    }
}
