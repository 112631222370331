import React from 'react';
import { ProfileSettingsPage } from './pages/ProfileSettingsPage';
import { OTPConfigurePage } from './pages/OTPConfigure';
import { ConnectedAccountsPage } from './pages/ConnectedAccountsPage';
import { matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { TabLayout } from '../../layouts/TabLayout';
import { ServiceAccountsPage } from '../general/pages/ServiceAccountsPage';
import { OrganizationsPage } from './pages/OrganizationsPage';
import { LeftMenuTab, LeftMenuTabs } from '../../components/LeftMenuTabs';

// Make billing page lazy loaded, to avoid loading Stripe JS on every page
const LazyBillingPage = React.lazy(() => import('../general/pages/BillingPage'));

export const ProfileSettingsView = () => {
    const location = useLocation();
    const routeMatch = matchPath('/settings/:section/*', location.pathname);
    const section = routeMatch?.params.section ?? 'general';

    return (
        <TabLayout>
            <LeftMenuTabs value={section}>
                <LeftMenuTab label={'Settings'} value={'general'} to={`general`} data-kap-id="settings-tab" />

                <LeftMenuTab
                    label={'Connections'}
                    value={'connections'}
                    to={`connections`}
                    data-kap-id="connections-tab"
                />

                <LeftMenuTab label={'2-Factor Authentication'} value={'mfa'} to={`mfa`} data-kap-id="2fa-tab" />

                <LeftMenuTab
                    label={'Service accounts'}
                    value={'service-accounts'}
                    to={`service-accounts`}
                    data-kap-id="service-accounts-tab"
                />

                <LeftMenuTab
                    label={'Organizations'}
                    value={'organizations'}
                    to={`organizations`}
                    data-kap-id="organizations-tab"
                />

                <LeftMenuTab label={'Billing'} value={'billing'} to={`billing`} data-kap-id="billing-tab" />
            </LeftMenuTabs>

            <Routes>
                <Route path={'general'} element={<ProfileSettingsPage />} />
                <Route path={'connections'} element={<ConnectedAccountsPage />} />
                <Route path={'mfa'} element={<OTPConfigurePage />} />
                <Route path={'service-accounts'} element={<ServiceAccountsPage />} />
                <Route path={'organizations'} element={<OrganizationsPage />} />
                <Route
                    path={'billing/*'}
                    element={
                        <React.Suspense fallback={null}>
                            <LazyBillingPage />
                        </React.Suspense>
                    }
                />

                <Route path={'*'} element={<Navigate to={`general`} replace />} />
            </Routes>
        </TabLayout>
    );
};
