import React, { useMemo, useState } from 'react';
import {
    AsyncValidatorFunction,
    debouncedValidator,
    FormButtons,
    FormField,
    KapFormDialog,
    showToasty,
    SimpleLoader,
    ToastType,
    useConfirm,
} from '@kapeta/ui-web-components';
import { emitContextChange } from '@kapeta/web-microfrontend/browser';

import api from '../../../service/APIService';
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import CogIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import MemberTableCell from '../../../components/MemberTableCell';
import { hasFullAccess } from '../../../views/organization/pages/OrganizationMembersPage/OrganizationMembersPage';
import { TabPage } from '../../../layouts/TabPage';
import { useCurrentUserId } from '../../../service/userHooks';
import { useAsyncRetry } from 'react-use';

interface Organization {
    handle: string;
    name: string;
}

export const OrganizationsPage = () => {
    const currentUserId = useCurrentUserId();
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const confirm = useConfirm();

    const memberships = useAsyncRetry(async () => {
        const result = await api.organizations().list(currentUserId);
        return result ?? [];
    }, [currentUserId]);

    async function createOrganization(data: Organization) {
        try {
            await api.organizations().create({
                handle: data.handle,
                name: data.name,
            });

            showToasty({
                type: ToastType.SUCCESS,
                message: 'Organization created',
                title: 'Success',
            });

            setCreateModalOpen(false);
            emitContextChange();
            return memberships.retry();
        } catch (error) {
            // Ignore
        }
    }

    async function leaveOrganization({ id, name }: { id: string; name: string }) {
        if (
            await confirm({
                title: 'Leave organization',
                content: (
                    <Typography variant="body1">
                        Are you sure you want to leave{' '}
                        <Typography variant="subtitle2" component="span">
                            {name}
                        </Typography>
                        ?
                        <Typography sx={{ mt: 2 }}>
                            {' '}
                            Leaving an organization will remove your access to all of its projects.
                        </Typography>
                    </Typography>
                ),
                confirmationButtonProps: { color: 'error' },
                confirmationText: 'Yes, leave organization',
            })
        ) {
            await api.organizations().leave(id);

            showToasty({
                type: ToastType.SUCCESS,
                message: 'Left organization',
                title: 'Success',
            });

            emitContextChange();
            return memberships.retry();
        }
    }

    const validateHandle: AsyncValidatorFunction = useMemo(
        () =>
            debouncedValidator(500, (name: string, value: string) => {
                let doResolve: Parameters<ConstructorParameters<typeof Promise>[0]>[0];
                const promise = new Promise((resolve, reject) => {
                    doResolve = resolve;

                    if (!value) {
                        doResolve(null);
                        return;
                    }

                    // Check if the handle already exists
                    api.identifiers()
                        .exists('handle', value)
                        .then((exists) => {
                            exists ? reject('This handle is already registered') : resolve(null);
                        })
                        .catch(() => null);
                });

                return {
                    promise,
                    cancel: () => {
                        doResolve && doResolve(null);
                    },
                };
            }),
        []
    );

    const membershipsSimple =
        memberships.value?.map((org) => {
            return {
                id: org.identity.id,
                name: org.identity.name,
                handle: org.identity.handle,
                permissions: org.scopes,
            };
        }) ?? [];

    return (
        <TabPage title="Organizations" introduction={'Manage your organizations'}>
            <SimpleLoader loading={memberships.loading}>
                <Table sx={{ minWidth: 400, mb: 3 }} aria-label="Organizations table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Permissions</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {membershipsSimple.map((membership) => (
                            <TableRow key={membership.id}>
                                <TableCell>
                                    <MemberTableCell name={membership.name} handle={membership.handle} />
                                </TableCell>
                                <TableCell>
                                    {hasFullAccess(Array.from(membership.permissions))
                                        ? 'Full access'
                                        : 'Limited access'}
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" flexDirection="row" gap={3} justifyContent={'flex-end'}>
                                        <IconButton
                                            data-kap-id="organization-settings"
                                            href={`/organizations/${membership.handle}/settings/general`}
                                            size="small"
                                            aria-label={`Settings for ${membership.name}`}
                                            title={`Settings for ${membership.name}`}
                                        >
                                            <CogIcon fontSize="inherit" />
                                        </IconButton>
                                        <IconButton
                                            data-kap-id="leave-organization"
                                            onClick={() => {
                                                leaveOrganization(membership).catch(() => null);
                                            }}
                                            size="small"
                                            color="warning"
                                            aria-label={`Leave organization ${membership.name}`}
                                            title={`Leave organization ${membership.name}`}
                                        >
                                            <LogoutIcon fontSize="inherit" />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Button
                    color="secondary"
                    onClick={() => setCreateModalOpen(true)}
                    variant="contained"
                    data-kap-id="create-organization"
                >
                    Create organization
                </Button>
            </SimpleLoader>

            <KapFormDialog
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                title="Create organization"
                onSubmitData={(data) => createOrganization(data as Organization)}
                actions={
                    <FormButtons addWrapperDiv={false}>
                        <Button
                            onClick={() => setCreateModalOpen(false)}
                            variant="text"
                            color="inherit"
                            data-kap-id="create-organization-cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color={'primary'}
                            type={'submit'}
                            data-kap-id="create-organization-submit"
                        >
                            Create
                        </Button>
                    </FormButtons>
                }
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    paddingTop={1}
                    sx={{
                        '.MuiFormControl-root': { my: 0 }, // Remove hardcoded margins
                    }}
                >
                    <FormField name="name" label="Name" validation={['required']} variant="outlined" />
                    <FormField
                        name="handle"
                        label="Handle"
                        validation={['required', validateHandle]}
                        variant="outlined"
                    />
                </Box>
            </KapFormDialog>
        </TabPage>
    );
};
