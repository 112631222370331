//
// GENERATED SOURCE - DO NOT EDIT
//

import { useMemo } from 'react';
import { RestClient } from '@kapeta/sdk-web-rest-client';
import { RestClientRequest } from '@kapeta/sdk-rest';
import { Invitation } from '../../../.generated/entities/Invitation';
import { CreateInvitationRequest } from '../../../.generated/entities/CreateInvitationRequest';

export type ConfigureIdentityInvitationsClient = (client: IdentityInvitationsClient) => IdentityInvitationsClient;

/**
 * Creates a new IdentityInvitationsClient for React components.
 * The client is configured with the provided configuration function.
 *
 * It is wrapped in a useMemo hook to ensure that the client is only created once.
 */
export const useIdentityInvitationsClient = (
    configure?: ConfigureIdentityInvitationsClient
): IdentityInvitationsClient => {
    return useMemo(() => {
        const client = new IdentityInvitationsClient();
        if (configure) {
            return configure(client);
        }
        return client;
    }, [configure]);
};

/**
 * A client for the IdentityInvitations API.
 * Use the useIdentityInvitationsClient hook to create a client in React.
 *
 * Use the IdentityInvitationsClient directly in other contexts.
 */
export class IdentityInvitationsClient extends RestClient {
    constructor() {
        super('api/rest/identityInvitations');
    }

    /**
     * HTTP: GET /api/rest/identityinvitations/identities/{identityId}/invitations
     */
    async list(identityId: string): Promise<Invitation[] | null> {
        const result = await this.$execute<Invitation[]>('GET', '/identities/{identityId}/invitations', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as Invitation[];
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identityinvitations/identities/{identityId}/invitations
     */
    listRequest(identityId: string): RestClientRequest<Invitation[] | null> {
        return this.$create<Invitation[]>('GET', '/identities/{identityId}/invitations', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: POST /api/rest/identityinvitations/identities/{identityId}/invitations
     */
    async create(identityId: string, createInvitationRequest: CreateInvitationRequest): Promise<Invitation | null> {
        const result = await this.$execute<Invitation>('POST', '/identities/{identityId}/invitations', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            {
                name: 'createInvitationRequest',
                value: createInvitationRequest,
                transport: 'BODY',
                typeName: 'CreateInvitationRequest',
            },
        ]);

        if (result === null) {
            return null;
        }
        return result as Invitation;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/identityinvitations/identities/{identityId}/invitations
     */
    createRequest(
        identityId: string,
        createInvitationRequest: CreateInvitationRequest
    ): RestClientRequest<Invitation | null> {
        return this.$create<Invitation>('POST', '/identities/{identityId}/invitations', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            {
                name: 'createInvitationRequest',
                value: createInvitationRequest,
                transport: 'BODY',
                typeName: 'CreateInvitationRequest',
            },
        ]);
    }
}
