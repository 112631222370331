import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Application } from '../Application';
import { theme } from '../Theme';

import './index.less';
import { asFragment } from '@kapeta/web-microfrontend/browser';

declare global {
    interface KapetaGlobl {
        config?: {
            stripePublicKey?: string;
        };
    }
}

const FragmentApplication = asFragment(() => <Application />);

export const IdentityProviderPage = () => {
    return (
        <ThemeProvider theme={theme}>
            <FragmentApplication />
        </ThemeProvider>
    );
};
