import React from 'react';
import { matchPath, Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { TabLayout } from '../../layouts/TabLayout';
import { ServiceAccountsPage } from '../general/pages/ServiceAccountsPage';
import { OrganizationClientsPage } from './pages/OrganizationClientsPage/OrganizationClientsPage';
import { OrganizationMembersPage } from './pages/OrganizationMembersPage/OrganizationMembersPage';
import { OrganizationSettingsPage } from './pages/OrganizationSettingsPage';
import { LeftMenuTab, LeftMenuTabs } from '../../components/LeftMenuTabs';

// Make billing page lazy loaded, to avoid loading Stripe JS on every page
const LazyBillingPage = React.lazy(() => import('../general/pages/BillingPage'));

export const OrganizationSettingsView = () => {
    const { handle = '' } = useParams();
    const location = useLocation();
    const routeMatch = matchPath('/organizations/:handle/settings/:section/*', location.pathname);
    const section = routeMatch?.params.section ?? 'general';

    return (
        <TabLayout>
            <LeftMenuTabs value={section}>
                <LeftMenuTab label="Settings" value="general" to={`general`} data-kap-id="settings-tab" />

                <LeftMenuTab label="Members" value="members" to={`members`} data-kap-id="members-tab" />

                <LeftMenuTab
                    label="Service accounts"
                    value="service-accounts"
                    to={`service-accounts`}
                    data-kap-id="service-accounts-tab"
                />

                <LeftMenuTab label="OAuth2 Clients" value="oauth2" to={`oauth2`} data-kap-id="oauth2-tab" />

                <LeftMenuTab label={'Billing'} value={'billing'} to={`billing`} data-kap-id="billing-tab" />
            </LeftMenuTabs>

            <Routes>
                <Route path="general" element={<OrganizationSettingsPage handle={handle} />} />
                <Route path="members/*" element={<OrganizationMembersPage handle={handle} />} />
                <Route path="service-accounts" element={<ServiceAccountsPage handle={handle} />} />
                <Route path="oauth2" element={<OrganizationClientsPage handle={handle} />} />
                <Route
                    path={'billing/*'}
                    element={
                        <React.Suspense fallback={null}>
                            <LazyBillingPage />
                        </React.Suspense>
                    }
                />

                <Route path="*" element={<Navigate to={`general`} replace />} />
            </Routes>
        </TabLayout>
    );
};
