import React from 'react';

import { SimpleLoader } from '@kapeta/ui-web-components';
import { useFramePageTitle } from '@kapeta/web-microfrontend/browser';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { TabPage } from '../../../layouts/TabPage';
import { Alert, Box, Stack } from '@mui/material';
import { EditHandleForm } from '../../../components/EditHandleForm';
import { useOrganizationSettings } from '../../../hooks/useOrganizationSettings';
import { EditOrganizationNameForm } from '../../../components/EditOrganizationNameForm';
import { SystemAdminForms } from '../../../components/SystemAdminForms';

interface Props {
    handle: string;
}

const ignore = () => null;

export const OrganizationSettingsPage = (props: Props) => {
    const { pathname } = useLocation();
    const { organization, setNewHandleInOrganization, reloadOrganization, handleIdentifier, canEdit } =
        useOrganizationSettings({
            handle: props.handle,
        });

    const navigate = useNavigate();
    const onHandleChange = (newHandle: string) => {
        navigate(generatePath(pathname.replace(props.handle, ':handle'), { handle: newHandle }), { replace: true });
    };

    useFramePageTitle(organization?.identity.name);

    return (
        <TabPage title="Organization settings" introduction={'Configure your organization'}>
            <Box
                sx={{
                    '& .MuiFormControl-root': { my: 0 }, // Remove hardcoded margins from all form controls
                }}
            >
                <SimpleLoader loader={reloadOrganization}>
                    {organization && (
                        <Stack direction="column" gap={3}>
                            <Stack direction="column" gap={2}>
                                <Stack direction="row" gap={3} alignItems={'flex-start'}>
                                    <EditOrganizationNameForm
                                        flex={1}
                                        identity={organization.identity}
                                        onNameChange={() => {
                                            reloadOrganization().catch(ignore);
                                        }}
                                        disabled={!canEdit}
                                    />
                                    <EditHandleForm
                                        // Disabled until we figure out how to handle renames on the backend
                                        // https://linear.app/kapeta/issue/KAP-1201/[bug]-changing-handle-breaks-links-in-blockhub
                                        disabled
                                        flex={1}
                                        identityId={organization.identity.id}
                                        handleIdentifier={handleIdentifier}
                                        onHandleChange={(newHandle) => {
                                            onHandleChange?.(newHandle);
                                            setNewHandleInOrganization(newHandle);
                                            reloadOrganization(newHandle).catch(ignore);
                                        }}
                                    />
                                </Stack>

                                {organization?.identity?.id && (
                                    <SystemAdminForms
                                        handle={organization.identity.handle}
                                        identityType={organization.identity.type}
                                        identityId={organization.identity?.id}
                                    />
                                )}
                            </Stack>

                            <Alert severity="warning" sx={{ mt: 3 }}>
                                <strong>Delete organization</strong>
                                <br />
                                Contact support if you want to delete this organization.
                            </Alert>
                        </Stack>
                    )}
                </SimpleLoader>
            </Box>
        </TabPage>
    );
};
