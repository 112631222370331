import React, { useState } from 'react';
import { AuthScopesField, FormContainer, KapDialog, ToastType, showToasty } from '@kapeta/ui-web-components';

import api from '../../../../service/APIService';
import { Member } from './OrganizationMembersPage';
import { Scopes } from '../../../../data/scopes';
import { Button, CircularProgress } from '@mui/material';
import { MemberIdentity } from '../../../../../.generated/entities/MemberIdentity';

type EditMemberDialogProps = {
    open: boolean;
    onClose: (didUpdateMember?: boolean) => void;
    organization?: MemberIdentity;
    member?: Member;
};

export function EditMemberDialog({ open, onClose, organization, member }: EditMemberDialogProps) {
    const [isUpdating, setIsUpdating] = useState(false);
    async function updateMember(data: Member) {
        if (!organization) {
            return;
        }

        setIsUpdating(true);
        try {
            const identity = await api.identities('user').getPublic(data.handle);

            if (!identity) {
                showToasty({
                    type: ToastType.DANGER,
                    message: 'Identity not found',
                    title: 'Not found',
                });
                return;
            }

            await api
                .organizations()
                .join(organization.identity.id, identity.id, data.scopes)
                .then(() => {
                    showToasty({
                        type: ToastType.SUCCESS,
                        message: `Permissions changed for ${data.handle}`,
                        title: 'Member updated',
                    });
                })
                .catch(() => {
                    showToasty({
                        type: ToastType.DANGER,
                        message: `Failed to update permissions for ${data.handle}`,
                        title: 'Member update failed',
                    });
                });
        } catch (error) {
            // Ignore
        } finally {
            setIsUpdating(false);
        }

        onClose(true);
    }

    return (
        <KapDialog open={open} onClose={onClose}>
            <FormContainer initialValue={member} onSubmitData={(formData) => updateMember(formData as Member)}>
                <KapDialog.Title>{`Edit permissions for ${member?.handle ?? ''}`}</KapDialog.Title>
                <KapDialog.Content dividers sx={{ borderBottom: 'none' }}>
                    <AuthScopesField scopes={Scopes} name="scopes" />
                </KapDialog.Content>
                <KapDialog.Actions>
                    <Button onClick={() => onClose()}>Cancel</Button>
                    <Button
                        type="submit"
                        variant="contained"
                        endIcon={isUpdating ? <CircularProgress size={20} color="inherit" /> : null}
                        disabled={isUpdating}
                    >
                        Update
                    </Button>
                </KapDialog.Actions>
            </FormContainer>
        </KapDialog>
    );
}
