import React from 'react';

import './ProfileLayout.less';

export interface LayoutProps {
    children: any;
}

export const ProfileLayout = (props: LayoutProps) => {
    return <div className={'profile-layout'}>{props.children}</div>;
};
