import { currentIdentity } from '@kapeta/web-microfrontend/browser';
import { IdentityAuthenticationClient } from '../.generated/clients/IdentityAuthenticationClient';
import { IdentityClient } from '../.generated/clients/IdentityClient';
import { SearchType } from '../../.generated/entities/SearchType';
import { Identity } from '../../.generated/entities/Identity';
import { CreateIdentifierRequest } from '../../.generated/entities/CreateIdentifierRequest';
import { Client } from '../../.generated/entities/Client';
import { IdentityClientsClient } from '../.generated/clients/IdentityClientsClient';
import { IdentityIdentifierClient } from '../.generated/clients/IdentityIdentifierClient';
import { ValueBody } from '../../.generated/entities/ValueBody';
import { ServiceAccountClient } from '../.generated/clients/ServiceAccountClient';
import { TypedRequest } from '../../.generated/entities/TypedRequest';
import { UserClient } from '../.generated/clients/UserClient';
import { SystemIdentityScopesClient } from '../.generated/clients/SystemIdentityScopesClient';
import { IdentityInvitationsClient } from '../.generated/clients/IdentityInvitationsClient';
import { CreateInvitationRequest } from '../../.generated/entities/CreateInvitationRequest';

function currentIdentityId(): string | undefined {
    return currentIdentity()?.id;
}

export interface TwoFactorRequest {
    identityId?: string;
    code: string;
    secret: string;
}

export interface CreateOrganizationRequest {
    handle: string;
    name: string;
}

export interface CreateServiceAccountRequest {
    handle?: string;
    name: string;
    identityId: string;
    scopes: string[];
}

type IdentityType = 'user' | 'organization' | 'service';

class IdentityAPI {
    private readonly type: IdentityType;
    private readonly identities: IdentityClient;

    constructor(type: IdentityType) {
        this.identities = new IdentityClient();
        this.type = type;
    }

    async search(query: string) {
        const response = await this.identities.searchFor(query, this.type as SearchType, {
            size: 100,
            page: 0,
            sort: [],
        });

        return response?.content;
    }

    async get(identityId: string) {
        return this.identities.get(identityId);
    }

    async getAsMember(identityId: string) {
        return this.identities.getAsMember(identityId);
    }

    async getByHandle(handle: string) {
        return this.identities.getByHandle(handle);
    }

    async getByHandleAsMember(handle: string) {
        return this.identities.getByHandleAsMember(handle);
    }

    async getPublic(handle: string) {
        return this.identities.getBriefByHandle(handle);
    }

    async update(identity: Identity) {
        identity.type = this.type;
        return this.identities.update(identity.id, identity);
    }

    async create(data: any) {
        return this.identities.register({
            type: this.type,
            data,
        });
    }

    async remove(identityId: string) {
        return this.identities.delete(identityId);
    }

    async getMemberships(identityId: string) {
        return this.identities.listMemberships(identityId, this.type);
    }

    async getMembers(identityId: string, type: string) {
        return this.identities.listMembers(identityId, type);
    }

    async addMember(identityId: string, memberId?: string, scopes?: string[]) {
        if (!memberId) {
            memberId = currentIdentityId();
        }

        if (!scopes) {
            scopes = [];
        }

        return this.identities.addMember(identityId, memberId!, scopes);
    }

    async removeMember(identityId: string, memberId?: string) {
        if (!memberId) {
            memberId = currentIdentityId();
        }

        return this.identities.removeMember(identityId, memberId!);
    }
}

class ProfileAPI {
    private users: UserClient;
    private identity: IdentityAPI;
    private scopes: SystemIdentityScopesClient;

    constructor(api: APIService) {
        this.users = new UserClient();
        this.scopes = new SystemIdentityScopesClient();
        this.identity = new IdentityAPI('user');
    }

    async getUser(identityId: string) {
        return this.identity.get(identityId);
    }

    async updateUser(identity: Identity) {
        return this.identity.update(identity);
    }

    async updatePassword(identityId: string, oldPassword: string, newPassword: string) {
        return this.users.updatePassword(identityId, { oldPassword, newPassword });
    }

    async resetPassword(identityId: string) {
        return this.users.resetPasswordForUser(identityId);
    }

    async getUserScopes(identityId: string) {
        return this.scopes.get(identityId);
    }

    async setUserScopes(identityId: string, scopes: string[]) {
        return this.scopes.set(identityId, { scopes: scopes });
    }
}

class OneTimePasswordAuthenticationAPI {
    private readonly authentication: IdentityAuthenticationClient;
    private readonly type: string = 'urn:oauth:grant-type:mfa-otp';

    constructor() {
        this.authentication = new IdentityAuthenticationClient();
    }

    async get(identityId: string) {
        return this.authentication.getAuthentications(identityId, this.type);
    }

    async configure(identityId: string, twoFactor: TwoFactorRequest) {
        twoFactor.identityId = identityId;
        return this.authentication.createAuthentications(identityId, { type: this.type, data: twoFactor });
    }

    async disable(identityId: string, id: string) {
        return this.authentication.deleteAuthentications(identityId, id);
    }
}

class ConnectionsAPI {
    private readonly authentication: IdentityAuthenticationClient;
    private readonly type: string = 'urn:oauth:grant-type:external-oauth2';

    constructor() {
        this.authentication = new IdentityAuthenticationClient();
    }

    async list(identityId: string) {
        return this.authentication.getAuthentications(identityId, this.type);
    }

    async connect(identityId: string, authentication: TypedRequest) {
        return this.authentication.createAuthentications(identityId, authentication);
    }

    async disconnect(identityId: string, authenticationId: string) {
        return this.authentication.deleteAuthentications(identityId, authenticationId);
    }
}

class IdentifierAPI {
    private api: IdentityIdentifierClient;

    constructor() {
        this.api = new IdentityIdentifierClient();
    }

    async list(identityId: string) {
        return this.api.list(identityId);
    }

    async remove(identityId: string, identifierId: string) {
        return this.api.removeIdentifier(identityId, identifierId);
    }

    async update(identityId: string, identifierId: string, value: string) {
        return this.api.updateIdentifier(identityId, identifierId, { value } as ValueBody);
    }

    async setAsPrimary(identityId: string, identifierId: string) {
        return this.api.updateIdentifier(identityId, identifierId, { primary: true } as ValueBody);
    }

    async verify(identityId: string, identifierId: string, code: string) {
        return this.api.verifyIdentifier(identityId, identifierId, { value: code } as ValueBody);
    }

    async exists(type: string, identifier: string) {
        return this.api.identifierExists(type, identifier);
    }

    async resend(identityId: string, identifierId: string) {
        return this.api.resendVerification(identityId, identifierId);
    }

    async register(identityId: string, request: CreateIdentifierRequest) {
        return this.api.registerIdentifier(identityId, request);
    }
}

class ClientsAPI {
    private readonly identityId: string;
    private readonly api: IdentityClientsClient;

    constructor(identityId: string) {
        this.identityId = identityId;
        this.api = new IdentityClientsClient();
    }

    async list() {
        return this.api.list(this.identityId);
    }

    async add(client: Client) {
        return this.api.createClient(this.identityId, client);
    }

    async update(client: Client) {
        client.identityId = this.identityId;
        return this.api.updateClient(this.identityId, client.id, client);
    }

    async remove(clientId: string) {
        return this.api.deleteClient(this.identityId, clientId);
    }
}

class OrganizationsAPI {
    private api: APIService;
    private identity: IdentityAPI;
    private identityInvitations: IdentityInvitationsClient;

    constructor(api: APIService) {
        this.api = api;
        this.identity = new IdentityAPI('organization');
        this.identityInvitations = new IdentityInvitationsClient();
    }

    async list(identityId: string) {
        return this.identity.getMemberships(identityId);
    }

    async members(organizationId: string, type: string) {
        return this.identity.getMembers(organizationId, type);
    }

    async get(organizationId: string) {
        return this.identity.get(organizationId);
    }

    async getByHandle(handle: string) {
        return this.identity.getByHandle(handle);
    }

    async getByHandleAsMember(handle: string) {
        return this.identity.getByHandleAsMember(handle);
    }

    async create(organization: CreateOrganizationRequest) {
        return this.identity.create(organization);
    }

    async update(organization: Identity) {
        return this.identity.update(organization);
    }

    async remove(organizationId: string) {
        return this.identity.remove(organizationId);
    }

    async join(organizationId: string, identityId?: string, scopes?: string[]) {
        return this.identity.addMember(organizationId, identityId, scopes);
    }

    async leave(organizationId: string, identityId?: string) {
        return this.identity.removeMember(organizationId, identityId);
    }

    async invite(organizationId: string, request: CreateInvitationRequest) {
        return this.identityInvitations.create(organizationId, request);
    }

    async invitations(organizationId: string) {
        return this.identityInvitations.list(organizationId);
    }
}

class ServiceAccountAPI {
    private serviceAccount: ServiceAccountClient;
    private identity: IdentityAPI;

    constructor(api: APIService) {
        this.serviceAccount = new ServiceAccountClient();
        this.identity = new IdentityAPI('service');
    }

    async list(identityId: string) {
        return this.identity.getMembers(identityId, 'service');
    }

    async get(serviceAccountId: string) {
        return this.identity.get(serviceAccountId);
    }

    async getJWTForServiceAccount(organizationId: string, serviceAccountId: string) {
        return this.serviceAccount.exportJWT(organizationId, serviceAccountId);
    }

    async update(serviceAccount: Identity) {
        return this.identity.update(serviceAccount);
    }

    async create(serviceAccount: CreateServiceAccountRequest) {
        return this.identity.create(serviceAccount);
    }

    async remove(organizationId: string, serviceAccountId: string) {
        return this.identity.removeMember(organizationId, serviceAccountId);
    }
}

export class APIService {
    public profile(): ProfileAPI {
        return new ProfileAPI(this);
    }

    public otp(): OneTimePasswordAuthenticationAPI {
        return new OneTimePasswordAuthenticationAPI();
    }

    public connections(): ConnectionsAPI {
        return new ConnectionsAPI();
    }

    public identifiers(): IdentifierAPI {
        return new IdentifierAPI();
    }

    public identities(type: IdentityType): IdentityAPI {
        return new IdentityAPI(type);
    }

    public organizations(): OrganizationsAPI {
        return new OrganizationsAPI(this);
    }

    public clients(identityId: string): ClientsAPI {
        return new ClientsAPI(identityId);
    }

    public serviceAccounts(): ServiceAccountAPI {
        return new ServiceAccountAPI(this);
    }
}

export const api = new APIService();
export default api;
