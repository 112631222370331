import React, { useCallback, useState } from 'react';
import api from '../../../../service/APIService';

import { FormData, showToasty, SimpleLoader, ToastType, useConfirm } from '@kapeta/ui-web-components';
import { SCOPE_IDENTITY_WRITE } from '../../../../data/scopes';
import { hasAccess } from '@kapeta/web-microfrontend/browser';
import { TabPage } from '../../../../layouts/TabPage';
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Oauth2ClientDialog } from './Oauth2ClientDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ClientType } from '../../../../../.generated/entities/ClientType';
import { Client } from '../../../../../.generated/entities/Client';
import { MemberIdentity } from '../../../../../.generated/entities/MemberIdentity';

interface OrganizationClientsPageProps {
    handle: string;
}

export const OrganizationClientsPage = (props: OrganizationClientsPageProps) => {
    const [organization, setOrganization] = useState<MemberIdentity>();
    const [clients, setClients] = useState<Client[]>([]);
    const [clientToEdit, setClientToEdit] = useState<Client>();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const confirm = useConfirm();

    const canEdit = organization && hasAccess(Array.from(organization.scopes), SCOPE_IDENTITY_WRITE);

    const loader = useCallback(async () => {
        let orgId;
        if (!organization) {
            const org = await api.organizations().getByHandleAsMember(props.handle);
            if (!org) {
                return;
            }
            setOrganization(org);
            orgId = org.identity.id;
        } else {
            orgId = organization.identity.id;
        }

        const result = await api.clients(orgId).list();
        if (result && result.length > 0) {
            setClients(result);
        } else {
            setClients([]);
        }
    }, [organization, props.handle]);

    const closeModal = () => {
        setIsModalOpen(false);
        setClientToEdit(undefined);
    };

    const openModal = (client?: Client) => {
        if (client) {
            setClientToEdit({ ...client, secret: '••••••••••••••••••••' });
        }
        setIsModalOpen(true);
    };

    const saveClient = async (data: FormData) => {
        if (!organization) {
            return;
        }

        if (clientToEdit) {
            await api.clients(organization.identity.id).update({
                id: clientToEdit.id,
                identityId: clientToEdit.identityId,
                type: clientToEdit.type,
                name: (data.name as string).trim(),
                secret: clientToEdit.secret,
                allowedGrantTypes: clientToEdit.allowedGrantTypes,
                scopes: data.scopes as string[],
                redirectUris: clientToEdit.redirectUris,
            });

            showToasty({
                type: ToastType.SUCCESS,
                message: `Client was updated`,
                title: 'Client updated',
            });
        } else {
            await api.clients(organization.identity.id).add({
                type: ClientType.SERVER,
                id: '',
                identityId: organization.identity.id,
                name: (data.name as string).trim(),
                secret: (data.secret as string).trim(),
                allowedGrantTypes: data.allowedGrantTypes,
                scopes: data.scopes,
                redirectUris: [],
            });

            showToasty({
                type: ToastType.SUCCESS,
                message: `Client was created`,
                title: 'Client created',
            });
        }

        closeModal();

        await loader();
    };

    const removeClient = async (id: string) => {
        if (!organization) {
            return;
        }

        if (
            await confirm({
                title: 'Delete client?',
                content: 'Action can not be undone',
                confirmationText: 'Delete',
                confirmationButtonProps: { color: 'error' },
            })
        ) {
            try {
                await api.clients(organization.identity.id).remove(id);

                showToasty({
                    type: ToastType.SUCCESS,
                    message: 'Client was removed',
                    title: 'Success',
                });

                await loader();
            } catch (error) {
                // Ignore
            }
        }
    };

    return (
        <TabPage title="OAuth2 Clients" introduction={'Manage OAuth2 Clients for this organization'}>
            <SimpleLoader loader={loader}>
                {clients.length > 0 && (
                    <Table sx={{ mb: 3 }} aria-lable="OAuth2 Clients table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Client ID</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clients.map((c) => (
                                <TableRow key={c.id}>
                                    <TableCell>{c.name}</TableCell>
                                    <TableCell>{c.id}</TableCell>
                                    <TableCell
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 3,
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <IconButton
                                            data-kap-id="edit-service-account"
                                            aria-label="Edit service account"
                                            title={'Edit service account'}
                                            color="primary"
                                            size="small"
                                            onClick={() => openModal(c)}
                                        >
                                            <EditIcon fontSize="inherit" />
                                        </IconButton>
                                        <IconButton
                                            data-kap-id="remove-service-account"
                                            aria-label="Remove service account"
                                            title={'Remove service account'}
                                            color="error"
                                            size="small"
                                            onClick={() => removeClient(c.id)}
                                        >
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}

                {canEdit && (
                    <Button
                        variant="contained"
                        color={'secondary'}
                        onClick={() => openModal()}
                        data-kap-id="add-service-account"
                    >
                        Add client
                    </Button>
                )}
            </SimpleLoader>

            <Oauth2ClientDialog
                key={clientToEdit?.id || 'new'}
                open={isModalOpen}
                client={clientToEdit}
                onClose={closeModal}
                onSave={(data) => saveClient(data)}
            />
        </TabPage>
    );
};
