import { Box, BoxProps } from '@mui/material';
import React, { useMemo } from 'react';
import { useAsyncRetry } from 'react-use';
import { SimpleLoader } from '@kapeta/ui-web-components';
import { InlineEditFormField } from './InlineEditFormField';

type Props<T> = {
    label: string;
    defaultValue: T;
    getter: () => Promise<T>;
    setter: (value: T) => Promise<void>;
} & BoxProps;

const FIELD_NAME = 'single';

export const EditSingleValueForm = <T,>(props: Props<T>) => {
    const { label, defaultValue, getter, setter, ...boxProps } = props;

    const value = useAsyncRetry(async () => {
        return getter();
    }, [getter]);

    const initialValue = useMemo(
        () => ({
            [FIELD_NAME]: value.value ?? defaultValue,
        }),
        [value.value, defaultValue]
    );

    return (
        <Box display="flex" flexDirection="column" {...boxProps} gap={3}>
            <SimpleLoader loading={value.loading}>
                <InlineEditFormField
                    formContainerProps={{
                        initialValue,
                        onSubmitData: async (data: any) => {
                            await setter(data[FIELD_NAME] as T);
                            value.retry();
                        },
                    }}
                    formFieldProps={{
                        variant: 'outlined',
                        name: FIELD_NAME,
                        label: label,
                    }}
                    defaultValue={defaultValue}
                />
            </SimpleLoader>
        </Box>
    );
};
