import React, { useMemo, useState } from 'react';
import { Box, BoxProps, Button, Chip, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { InlineEditFormField } from './InlineEditFormField';
import api from '../service/APIService';
import { ToastType, showToasty, useConfirm } from '@kapeta/ui-web-components';
import { useAsyncRetry } from 'react-use';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
    identityId: string;
} & BoxProps;

export const EditSystemScopesForm = (props: Props) => {
    const { identityId, ...boxProps } = props;

    const [isAddingScope, setIsAddingScope] = useState(false);
    const confirm = useConfirm();
    const userScopes = useAsyncRetry(async () => {
        return api.profile().getUserScopes(identityId);
    }, [identityId]);

    const addScope = async (value: string) => {
        if (!value || !userScopes.value) {
            return;
        }

        const scopes = (userScopes.value.scopes ? Array.from(userScopes.value.scopes) : []).map((s) => s.trim());
        if (scopes.includes(value.trim())) {
            showToasty({
                title: 'Alert',
                message: `Scope already added`,
                type: ToastType.ALERT,
            });
            return;
        }

        scopes.push(value.trim());

        await api.profile().setUserScopes(identityId, scopes);

        showToasty({
            title: 'Success',
            message: `Scope was added`,
            type: ToastType.SUCCESS,
        });
        setIsAddingScope(false);
        userScopes.retry();
    };

    const initialValue = useMemo(() => ({ scope: '' }), []);

    const scopes = Array.from(userScopes?.value?.scopes ?? []);

    return (
        <Box display="flex" flexDirection="column" {...boxProps} gap={3}>
            <Box display="flex" flexDirection="column" gap={3}>
                {scopes
                    .filter((scope) => scope !== '*')
                    .map((scope, ix) => (
                        <TextField
                            key={ix}
                            variant="outlined"
                            value={scope}
                            label="Scope"
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        onClick={async () => {
                                            const ok = await confirm({
                                                title: 'Remove scope?',
                                                content: (
                                                    <Typography variant="body1">
                                                        Are you sure you want to permanently remove{' '}
                                                        <Typography variant="subtitle2" component="span">
                                                            {scope}
                                                        </Typography>{' '}
                                                        from this user?
                                                    </Typography>
                                                ),
                                            });
                                            if (!ok) {
                                                return;
                                            }

                                            let scopes = Array.from(userScopes.value?.scopes ?? []);
                                            scopes = scopes.filter((s) => s !== scope);
                                            await api.profile().setUserScopes(identityId, scopes);
                                            await userScopes.retry();
                                            showToasty({
                                                title: 'Success',
                                                message: `Scope was removed`,
                                                type: ToastType.SUCCESS,
                                            });
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                ),
                            }}
                        />
                    ))}
            </Box>

            {isAddingScope ? (
                <InlineEditFormField
                    formContainerProps={{
                        initialValue,
                        onSubmitData: (data: any) => addScope(data.scope as string),
                    }}
                    formFieldProps={{
                        label: `Scope`,
                        name: 'scope',
                        validation: ['required'],
                        autoFocus: true,
                    }}
                    saveButtonText={`Add scope`}
                    onExitEditMode={() => setIsAddingScope(false)}
                />
            ) : (
                <Box display="flex" justifyContent="flex-end" sx={{ mt: '-8px' }}>
                    <Button
                        startIcon={<AddIcon fontSize="inherit" />}
                        variant="text"
                        color="inherit"
                        size="small"
                        onClick={() => setIsAddingScope(true)}
                    >
                        Add scope
                    </Button>
                </Box>
            )}
        </Box>
    );
};
