import React, { useMemo } from 'react';
import { InlineEditFormField } from './InlineEditFormField';
import api from '../service/APIService';
import { AsyncValidatorFunction, debouncedValidator, showToasty, ToastType } from '@kapeta/ui-web-components';
import { BoxProps } from '@mui/material';
import { IdentityIdentifier } from '../../.generated/entities/IdentityIdentifier';

type EditHandleFormProps = {
    identityId: string;
    handleIdentifier?: IdentityIdentifier;
    onHandleChange?: (newHandle: string) => void;
    disabled?: boolean;
} & BoxProps;

const ignore = () => null;

export const EditHandleForm = ({
    identityId,
    handleIdentifier,
    onHandleChange,
    disabled = false,
    ...boxProps
}: EditHandleFormProps) => {
    const updateHandle = async (newHandle: string) => {
        if (!handleIdentifier || !newHandle) {
            return;
        }
        try {
            await api.identifiers().update(identityId, handleIdentifier.id, newHandle);
            showToasty({
                title: 'Success',
                message: 'Handle was updated',
                type: ToastType.SUCCESS,
            });
            onHandleChange?.(newHandle);
        } catch (error) {
            // ignore
        }
    };

    const validateHandle: AsyncValidatorFunction = useMemo(
        () =>
            debouncedValidator(500, (name: string, value: string) => {
                let doResolve: Parameters<ConstructorParameters<typeof Promise>[0]>[0];
                const promise = new Promise((resolve, reject) => {
                    doResolve = resolve;

                    if (!value) {
                        doResolve(null);
                        return;
                    }

                    // Check if the handle is the currently registered handle for this user
                    if (handleIdentifier?.identifier === value) {
                        resolve(null);
                        return;
                    }

                    // Check if the handle is already registered by another user
                    api.identifiers()
                        .exists('handle', value)
                        .then((exists) => {
                            exists ? reject('This handle is already registered') : resolve(null);
                        })
                        .catch(ignore);
                });

                return {
                    promise,
                    cancel: () => {
                        doResolve && doResolve(null);
                    },
                };
            }),
        [handleIdentifier?.identifier]
    );

    const validation = useMemo(() => ['required', validateHandle], [validateHandle]);

    return (
        <InlineEditFormField
            {...boxProps}
            formContainerProps={{
                initialValue: useMemo(
                    () => ({
                        handle: handleIdentifier?.identifier ?? '',
                    }),
                    [handleIdentifier?.identifier]
                ),
                onSubmitData: (data: any) => updateHandle(data.handle as string),
            }}
            formFieldProps={{
                label: 'Handle',
                name: 'handle',
                validation,
                disabled,
            }}
        />
    );
};
