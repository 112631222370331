import React, { useState } from 'react';
import { SimpleLoader } from '@kapeta/ui-web-components';

import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Typography } from '@mui/material';
import EditMemberButton from './EditMemberButton';
import MemberTableCell from '../../../../components/MemberTableCell';
import { Member, hasFullAccess } from './OrganizationMembersPage';
import { EditMemberDialog } from './EditMemberDialog';
import { MemberIdentity } from '../../../../../.generated/entities/MemberIdentity';

type MembersTab = {
    organization?: MemberIdentity;
    canEdit?: boolean;
    members: MemberIdentity[];
    refetchData: () => Promise<void>;
};

export default function MembersTab({ organization, canEdit, members, refetchData }: MembersTab) {
    const [memberToEdit, setMemberToEdit] = useState<Member>();
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

    function openEditMemberModal(member: Member) {
        setMemberToEdit(member);
        setIsEditDialogOpen(true);
    }

    function closeEditMemberModal(didUpdateMember = false) {
        setIsEditDialogOpen(false);
        setMemberToEdit(undefined);
        if (didUpdateMember) {
            refetchData().catch(() => null);
        }
    }

    const membersList: Member[] = members
        .map((member) => {
            return {
                id: member.identity.id,
                name: member.identity.name,
                handle: member.identity.handle,
                scopes: Array.from(member.scopes),
            };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

    return (
        <>
            <SimpleLoader loader={refetchData}>
                <TableContainer>
                    <Table sx={{ minWidth: 400 }} aria-label="Members table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Permissions</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {membersList.map((member) => (
                                <TableRow key={member.handle}>
                                    <TableCell>
                                        <MemberTableCell name={member.name} handle={member.handle} />
                                    </TableCell>
                                    <TableCell>
                                        {canEdit ? (
                                            <Box onClick={() => openEditMemberModal(member)} sx={{ cursor: 'pointer' }}>
                                                <Typography variant="body2">
                                                    {hasFullAccess(member.scopes) ? 'Full access' : 'Limited access'}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Typography variant="body2">
                                                {hasFullAccess(member.scopes) ? 'Full access' : 'Limited access'}
                                            </Typography>
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {canEdit && (
                                            <EditMemberButton
                                                organization={organization}
                                                member={member}
                                                editMember={openEditMemberModal}
                                                onRemove={() => {
                                                    refetchData().catch(() => null);
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </SimpleLoader>

            <EditMemberDialog
                key={memberToEdit?.id ?? ''}
                open={isEditDialogOpen}
                onClose={closeEditMemberModal}
                organization={organization}
                member={memberToEdit}
            />
        </>
    );
}
