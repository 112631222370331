import React, { useMemo, useState } from 'react';
import { Box, BoxProps, Button, Chip, InputAdornment, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { InlineEditFormField } from './InlineEditFormField';
import api from '../service/APIService';
import { ToastType, showToasty } from '@kapeta/ui-web-components';
import { EditIdentifierButton } from './EditIdentifierButton';
import { VerifyIdentifierForm } from './VerifyIdentifierForm';
import { ExtendedIdentity } from '../../.generated/entities/ExtendedIdentity';
import { IdentityIdentifier } from '../../.generated/entities/IdentityIdentifier';

type EditIdentifiersFormProps = {
    identity: ExtendedIdentity | undefined;
    identifierType: 'email' | 'phone';
    identifiers: IdentityIdentifier[];
    onIdentifierChange?: () => void;
} & BoxProps;

const canDeleteIdentifier = (identifier: IdentityIdentifier) => {
    if (identifier.type === 'email' && identifier.primary === false) {
        // Only allow deleting non-primary emails
        return true;
    }
    if (identifier.type === 'phone') {
        // Allow deleting any phone numbers
        return true;
    }
    return false;
};

export const EditIdentifiersForm = ({
    identity,
    identifierType,
    identifiers,
    onIdentifierChange,
    ...boxProps
}: EditIdentifiersFormProps) => {
    const [isAddingIdentifier, setIsAddingIdentifier] = useState(false);

    const addIdentifier = async (value: string) => {
        if (!identity || !value) {
            return;
        }
        await api.identifiers().register(identity.id, { identifier: value, type: identifierType });
        showToasty({
            title: 'Success',
            message: `${identifierType === 'email' ? 'E-mail' : 'Phone number'} was added`,
            type: ToastType.SUCCESS,
        });
        onIdentifierChange?.();
        setIsAddingIdentifier(false);
    };

    const initialValue = useMemo(() => ({ [identifierType]: '' }), [identifierType]);

    if (!identity) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="column" {...boxProps} gap={3}>
            <Box display="flex" flexDirection="column" gap={3}>
                {/* No identifiers */}
                {identifiers.length === 0 && (
                    <InlineEditFormField
                        formContainerProps={{
                            initialValue,
                            onSubmitData: (data: any) => addIdentifier(data.value as string),
                        }}
                        formFieldProps={{
                            label: `${identifierType === 'email' ? 'E-mail address' : 'Phone number'}`,
                            name: 'value',
                            validation: ['required'],
                        }}
                    />
                )}

                {/* There are some identifiers */}
                {identifiers.length > 0 &&
                    identifiers
                        .sort((a, b) => {
                            // First sort by primary
                            if (a.primary && !b.primary) {
                                return -1;
                            }
                            if (!a.primary && b.primary) {
                                return 1;
                            }
                            // Then sort by verified
                            if (a.verified && !b.verified) {
                                return -1;
                            }
                            if (!a.verified && b.verified) {
                                return 1;
                            }
                            return 0;
                        })
                        .map((identifier) =>
                            identifier.verified ? (
                                <TextField
                                    key={identifier.id}
                                    variant="outlined"
                                    value={identifier.identifier}
                                    label={`${identifierType === 'email' ? 'E-mail address' : 'Phone number'}`}
                                    disabled
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                {identifier.primary && (
                                                    <InputAdornment position="start">
                                                        <Chip
                                                            label="Primary"
                                                            variant="outlined"
                                                            color="success"
                                                            size="small"
                                                        />
                                                    </InputAdornment>
                                                )}
                                                <EditIdentifierButton
                                                    identity={identity}
                                                    identifier={identifier}
                                                    canRemove={canDeleteIdentifier(identifier)}
                                                    onRemove={onIdentifierChange}
                                                    onPrimaryChange={onIdentifierChange}
                                                />
                                            </>
                                        ),
                                    }}
                                />
                            ) : (
                                <VerifyIdentifierForm
                                    key={identifier.id}
                                    identity={identity}
                                    identifier={identifier}
                                    onIdentifierChange={onIdentifierChange}
                                />
                            )
                        )}
            </Box>

            {isAddingIdentifier ? (
                <InlineEditFormField
                    formContainerProps={{
                        initialValue,
                        onSubmitData: (data: any) => addIdentifier(data[identifierType] as string),
                    }}
                    formFieldProps={{
                        label: `${identifierType === 'email' ? 'E-mail address' : 'Phone number'}`,
                        name: identifierType,
                        validation: identifierType === 'email' ? ['required', 'email'] : ['required'],
                        autoFocus: true,
                    }}
                    saveButtonText={`${identifierType === 'email' ? 'Add e-mail' : 'Add phone number'}`}
                    onExitEditMode={() => setIsAddingIdentifier(false)}
                />
            ) : (
                <Box display="flex" justifyContent="flex-end" sx={{ mt: '-8px' }}>
                    <Button
                        data-kap-id={`add-${identifierType}-button`}
                        startIcon={<AddIcon fontSize="inherit" />}
                        variant="text"
                        color="inherit"
                        size="small"
                        onClick={() => setIsAddingIdentifier(true)}
                    >
                        {`Add ${identifierType === 'email' ? 'e-mail' : 'phone number'}`}
                    </Button>
                </Box>
            )}
        </Box>
    );
};
