import React from 'react';

import { SimpleLoader } from '@kapeta/ui-web-components';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { hasFullAccess } from './OrganizationMembersPage';
import EditInvitationButton from './EditInvitationButton';
import { MemberIdentity } from '../../../../../.generated/entities/MemberIdentity';
import { Invitation } from '../../../../../.generated/entities/Invitation';

type InvitesTabProps = {
    organization?: MemberIdentity;
    canEdit?: boolean;
    invitations: Invitation[];
    refetchData: () => Promise<void>;
};

export default function InvitesTab({ organization, canEdit, invitations, refetchData }: InvitesTabProps) {
    const filteredInvitations = invitations.filter((invitation) => {
        return ['SENT', 'RESENT'].includes(invitation.state);
    });

    const sortedInvitations = filteredInvitations.sort((a, b) => {
        return a.inviteeEmail.localeCompare(b.inviteeEmail);
    });

    return (
        <SimpleLoader loader={refetchData}>
            <TableContainer>
                <Table sx={{ minWidth: 400 }} aria-label="Members table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>Permissions</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedInvitations.map((invitation) => (
                            <TableRow key={invitation.id}>
                                <TableCell>{invitation.inviteeEmail}</TableCell>
                                <TableCell>
                                    {hasFullAccess(Array.from(invitation.permissions))
                                        ? 'Full access'
                                        : 'Limited access'}
                                </TableCell>

                                <TableCell align="right">
                                    {canEdit && (
                                        <EditInvitationButton
                                            organization={organization}
                                            invitation={invitation}
                                            onRevoke={refetchData}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </SimpleLoader>
    );
}
