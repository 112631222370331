import React, { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';

export const TabLayout = (props: PropsWithChildren) => {
    return (
        <Stack
            direction={'row'}
            justifyContent={'stretch'}
            sx={{
                height: '100%',
                width: '100%',
            }}
        >
            {props.children}
        </Stack>
    );
};
