import { UserAvatar } from '@kapeta/ui-web-components';
import { Box, Typography } from '@mui/material';
import React from 'react';

type MemberTableCellProps = {
    name: string;
    handle: string;
};

/**
 * Renders a member to be put into a `TableCell`, displaying their avatar, name and handle.
 */
export default function MemberTableCell({ name, handle }: MemberTableCellProps): JSX.Element {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <UserAvatar name={name} />
            <Box sx={{ marginLeft: 3 }}>
                <Typography>{name}</Typography>
                <Typography color="text.secondary">{handle}</Typography>
            </Box>
        </Box>
    );
}
