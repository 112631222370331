import { useEffect, useState } from 'react';
import {
    FormContainerProps,
    FormFieldProps,
    FormContainer,
    FormContextWatcher,
    FormField,
    FormButtons,
} from '@kapeta/ui-web-components';
import { useTheme, BoxProps, Box, Stack, Button, ButtonProps } from '@mui/material';
import { usePrevious } from 'react-use';

type InlineEditFormFieldProps = {
    formContainerProps?: Omit<FormContainerProps<any>, 'children'>;
    formFieldProps: FormFieldProps;
    cancelButtonText?: string;
    cancelButtonProps?: ButtonProps;
    saveButtonText?: string;
    saveButtonProps?: ButtonProps;
    onExitEditMode?: () => void;
} & BoxProps;

export const InlineEditFormField = (props: InlineEditFormFieldProps) => {
    const {
        formContainerProps = {},
        formFieldProps,
        cancelButtonText = 'Cancel',
        cancelButtonProps,
        saveButtonText = 'Save',
        saveButtonProps,
        onExitEditMode,
        sx,
        ...otherBoxProps
    } = props;

    const [hasFocus, setHasFocus] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const isEditing = hasFocus || hasChanged;

    const prevIsEditing = usePrevious(isEditing);
    useEffect(() => {
        if (prevIsEditing && !isEditing) {
            onExitEditMode?.();
        }
    }, [isEditing, onExitEditMode, prevIsEditing]);

    const { spacing, palette } = useTheme();

    return (
        <Box
            sx={{
                ...sx,
                '.form-container': { backgroundColor: 'unset' }, // Remove the background color from form-container
                position: 'relative',
                mb: spacing(1.5),
                ...(isEditing
                    ? {
                          '&::before': {
                              content: '""',
                              position: 'absolute',
                              top: spacing(-1.5),
                              bottom: spacing(-1.5),
                              left: spacing(-1),
                              right: spacing(-1),
                              borderRadius: '6px',
                              backgroundColor: palette.grey[50],
                              pointerEvents: 'none',
                              zIndex: -1,
                          },
                      }
                    : {}),
            }}
            {...otherBoxProps}
        >
            <FormContainer {...formContainerProps} onSubmit={() => setHasFocus(false)}>
                <Stack direction={'column'} gap={1.5}>
                    <FormContextWatcher onChange={(context) => setHasChanged(context.isDirty)} />

                    <FormField
                        {...formFieldProps}
                        variant={formFieldProps?.variant || 'outlined'}
                        onFocus={() => setHasFocus(true)}
                        onBlur={() => setHasFocus(false)}
                    />

                    {isEditing && (
                        <FormButtons sx={{ pt: 0 }}>
                            <Button type="reset" variant="text" color="inherit" size="small" {...cancelButtonProps}>
                                {cancelButtonText}
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                size="small"
                                disabled={!hasChanged}
                                {...saveButtonProps}
                            >
                                {saveButtonText}
                            </Button>
                        </FormButtons>
                    )}
                </Stack>
            </FormContainer>
        </Box>
    );
};
