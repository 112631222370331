import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PublicProfileView } from '../views/profile/PublicProfileView';
import { ProfileSettingsView } from '../views/profile/ProfileSettingsView';
import { OrganizationSettingsView } from '../views/organization/OrganizationSettingsView';

export const Application = () => {
    return (
        <Routes>
            <Route path="/organizations/:handle/settings/*" element={<OrganizationSettingsView />} />
            <Route path="/settings/*" element={<ProfileSettingsView />} />
            <Route path="/view/:handle" element={<PublicProfileView />} />
            <Route path="*" element={<Navigate to={'/settings/general'} replace={true} />} />
        </Routes>
    );
};
