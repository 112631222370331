import React, { useState } from 'react';
import { OTPInput, ToastType, showToasty } from '@kapeta/ui-web-components';
import { Box, Stack, BoxProps, useTheme, TextField, Typography, Button, CircularProgress } from '@mui/material';
import api from '../service/APIService';
import { EditIdentifierButton } from './EditIdentifierButton';
import { ExtendedIdentity } from '../../.generated/entities/ExtendedIdentity';
import { IdentityIdentifier } from '../../.generated/entities/IdentityIdentifier';

type VerifyIdentifierFormProps = {
    identity: ExtendedIdentity;
    identifier: IdentityIdentifier;
    onIdentifierChange?: () => void;
} & BoxProps;

const ignore = () => null;

export const VerifyIdentifierForm = ({
    identity,
    identifier,
    onIdentifierChange,
    sx,
    ...otherBoxProps
}: VerifyIdentifierFormProps) => {
    const [isVerifying, setIsVerifying] = useState(false);
    const [isResending /* Not used yet */, setIsResending] = useState(false);

    const verifyIdentifier = async (code: string) => {
        try {
            setIsVerifying(true);
            await api.identifiers().verify(identity.id, identifier.id, code);
            showToasty({
                title: 'Success',
                message: `Successfully verified ${identifier.type === 'phone' ? 'phone number' : 'e-mail'}`,
                type: ToastType.SUCCESS,
            });
            onIdentifierChange?.();
        } catch (error) {
            // ignore
        } finally {
            setIsVerifying(false);
        }
    };

    const resendVerification = async () => {
        try {
            setIsResending(true);
            await api.identifiers().resend(identity.id, identifier.id);
            showToasty({
                title: 'Success',
                message: `Verification code was sent to ${identifier.identifier}`,
                type: ToastType.SUCCESS,
            });
        } catch (error) {
            // ignore
        } finally {
            setIsResending(false);
        }
    };

    const { spacing, palette } = useTheme();

    return (
        <Box
            sx={{
                ...sx,
                position: 'relative',
                my: spacing(1.5),
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: spacing(-1.5),
                    bottom: spacing(-1.5),
                    left: spacing(-1.5),
                    right: spacing(-1.5),
                    borderRadius: '6px',
                    backgroundColor: palette.grey[50],
                    pointerEvents: 'none',
                    zIndex: -1,
                },
            }}
            {...otherBoxProps}
        >
            <Stack direction={'column'} gap={1.5}>
                <TextField
                    variant="outlined"
                    value={identifier.identifier}
                    label={identifier.type === 'email' ? 'E-mail address' : 'Phone number'}
                    disabled
                    InputProps={{
                        endAdornment: (
                            <EditIdentifierButton
                                identity={identity}
                                identifier={identifier}
                                onRemove={onIdentifierChange}
                            />
                        ),
                    }}
                />

                <Box display="flex" alignItems={'flex-start'} gap={1.5}>
                    <OTPInput
                        onComplete={(code: string) => {
                            verifyIdentifier(code).catch(ignore);
                        }}
                        autoFocus
                    />
                    {isVerifying ? (
                        <CircularProgress size={24} color="inherit" sx={{ alignSelf: 'center' }} />
                    ) : (
                        <Typography variant="body2">
                            {`Verify ${
                                identifier.type === 'email' ? 'e-mail' : 'phone number'
                            } by entering the code you should have received.`}
                        </Typography>
                    )}
                    <Button
                        variant="text"
                        color="primary"
                        size="small"
                        sx={{ alignSelf: 'flex-end', ml: 'auto' }}
                        onClick={() => {
                            resendVerification().catch(ignore);
                        }}
                    >
                        Resend
                    </Button>
                </Box>
            </Stack>
        </Box>
    );
};
