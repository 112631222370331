//
// GENERATED SOURCE - DO NOT EDIT
//

import { useMemo } from 'react';
import { RestClient } from '@kapeta/sdk-web-rest-client';
import { RestClientRequest } from '@kapeta/sdk-rest';
import { SystemIdentityScopes } from '../../../.generated/entities/SystemIdentityScopes';
import { UpdateScopesRequest } from '../../../.generated/entities/UpdateScopesRequest';

export type ConfigureSystemIdentityScopesClient = (client: SystemIdentityScopesClient) => SystemIdentityScopesClient;

/**
 * Creates a new SystemIdentityScopesClient for React components.
 * The client is configured with the provided configuration function.
 *
 * It is wrapped in a useMemo hook to ensure that the client is only created once.
 */
export const useSystemIdentityScopesClient = (
    configure?: ConfigureSystemIdentityScopesClient
): SystemIdentityScopesClient => {
    return useMemo(() => {
        const client = new SystemIdentityScopesClient();
        if (configure) {
            return configure(client);
        }
        return client;
    }, [configure]);
};

/**
 * A client for the SystemIdentityScopes API.
 * Use the useSystemIdentityScopesClient hook to create a client in React.
 *
 * Use the SystemIdentityScopesClient directly in other contexts.
 */
export class SystemIdentityScopesClient extends RestClient {
    constructor() {
        super('api/rest/systemIdentityScopes');
    }

    /**
     * HTTP: GET /api/rest/systemidentityscopes/identities/{identityId}/scopes
     */
    async get(identityId: string): Promise<SystemIdentityScopes | null> {
        const result = await this.$execute<SystemIdentityScopes>('GET', '/identities/{identityId}/scopes', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as SystemIdentityScopes;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/systemidentityscopes/identities/{identityId}/scopes
     */
    getRequest(identityId: string): RestClientRequest<SystemIdentityScopes | null> {
        return this.$create<SystemIdentityScopes>('GET', '/identities/{identityId}/scopes', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: POST /api/rest/systemidentityscopes/identities/{identityId}/scopes
     */
    async set(identityId: string, updateScopesRequest: UpdateScopesRequest): Promise<SystemIdentityScopes | null> {
        const result = await this.$execute<SystemIdentityScopes>('POST', '/identities/{identityId}/scopes', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            {
                name: 'updateScopesRequest',
                value: updateScopesRequest,
                transport: 'BODY',
                typeName: 'UpdateScopesRequest',
            },
        ]);

        if (result === null) {
            return null;
        }
        return result as SystemIdentityScopes;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/systemidentityscopes/identities/{identityId}/scopes
     */
    setRequest(
        identityId: string,
        updateScopesRequest: UpdateScopesRequest
    ): RestClientRequest<SystemIdentityScopes | null> {
        return this.$create<SystemIdentityScopes>('POST', '/identities/{identityId}/scopes', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            {
                name: 'updateScopesRequest',
                value: updateScopesRequest,
                transport: 'BODY',
                typeName: 'UpdateScopesRequest',
            },
        ]);
    }
}
