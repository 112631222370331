//
// GENERATED SOURCE - DO NOT EDIT
//

import { useMemo } from 'react';
import { RestClient } from '@kapeta/sdk-web-rest-client';
import { RestClientRequest } from '@kapeta/sdk-rest';
import { Client } from '../../../.generated/entities/Client';

export type ConfigureIdentityClientsClient = (client: IdentityClientsClient) => IdentityClientsClient;

/**
 * Creates a new IdentityClientsClient for React components.
 * The client is configured with the provided configuration function.
 *
 * It is wrapped in a useMemo hook to ensure that the client is only created once.
 */
export const useIdentityClientsClient = (configure?: ConfigureIdentityClientsClient): IdentityClientsClient => {
    return useMemo(() => {
        const client = new IdentityClientsClient();
        if (configure) {
            return configure(client);
        }
        return client;
    }, [configure]);
};

/**
 * A client for the IdentityClients API.
 * Use the useIdentityClientsClient hook to create a client in React.
 *
 * Use the IdentityClientsClient directly in other contexts.
 */
export class IdentityClientsClient extends RestClient {
    constructor() {
        super('api/rest/identityClients');
    }

    /**
     * HTTP: PUT /api/rest/identityclients/identities/{identityId}/clients/{clientId}
     */
    async updateClient(identityId: string, clientId: string, client: Client): Promise<Client | null> {
        const result = await this.$execute<Client>('PUT', '/identities/{identityId}/clients/{clientId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'clientId', value: clientId, transport: 'PATH', typeName: 'string' },
            { name: 'client', value: client, transport: 'BODY', typeName: 'Client' },
        ]);

        if (result === null) {
            return null;
        }
        return result as Client;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: PUT /api/rest/identityclients/identities/{identityId}/clients/{clientId}
     */
    updateClientRequest(identityId: string, clientId: string, client: Client): RestClientRequest<Client | null> {
        return this.$create<Client>('PUT', '/identities/{identityId}/clients/{clientId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'clientId', value: clientId, transport: 'PATH', typeName: 'string' },
            { name: 'client', value: client, transport: 'BODY', typeName: 'Client' },
        ]);
    }

    /**
     * HTTP: DELETE /api/rest/identityclients/identities/{identityId}/clients/{clientId}
     */
    async deleteClient(identityId: string, clientId: string): Promise<void> {
        await this.$execute<void>('DELETE', '/identities/{identityId}/clients/{clientId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'clientId', value: clientId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: DELETE /api/rest/identityclients/identities/{identityId}/clients/{clientId}
     */
    deleteClientRequest(identityId: string, clientId: string): RestClientRequest<void> {
        return this.$create<void>('DELETE', '/identities/{identityId}/clients/{clientId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'clientId', value: clientId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identityclients/identities/{identityId}/clients
     */
    async list(identityId: string): Promise<Client[] | null> {
        const result = await this.$execute<Client[]>('GET', '/identities/{identityId}/clients', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as Client[];
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identityclients/identities/{identityId}/clients
     */
    listRequest(identityId: string): RestClientRequest<Client[] | null> {
        return this.$create<Client[]>('GET', '/identities/{identityId}/clients', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: POST /api/rest/identityclients/identities/{identityId}/clients
     */
    async createClient(identityId: string, client: Client): Promise<Client | null> {
        const result = await this.$execute<Client>('POST', '/identities/{identityId}/clients', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'client', value: client, transport: 'BODY', typeName: 'Client' },
        ]);

        if (result === null) {
            return null;
        }
        return result as Client;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/identityclients/identities/{identityId}/clients
     */
    createClientRequest(identityId: string, client: Client): RestClientRequest<Client | null> {
        return this.$create<Client>('POST', '/identities/{identityId}/clients', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'client', value: client, transport: 'BODY', typeName: 'Client' },
        ]);
    }
}
