import React, { useCallback, useState } from 'react';
import { flatten } from 'lodash';
import { hasAccess, Scope } from '@kapeta/web-microfrontend/browser';
import api from '../../../../service/APIService';
import { SCOPE_IDENTITY_WRITE, Scopes } from '../../../../data/scopes';
import { InviteMemberDialog } from './InviteMemberDialog/InviteMemberDialog';
import { Box, Button, Tab, Tabs } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Link, matchPath, Route, Routes, useLocation } from 'react-router-dom';
import MembersTab from './MembersTab';
import InvitesTab from './InvitesTab';
import { TabPage } from '../../../../layouts/TabPage';
import { MemberIdentity } from '../../../../../.generated/entities/MemberIdentity';
import { Invitation } from '../../../../../.generated/entities/Invitation';

export type Member = {
    id: string;
    name: string;
    handle: string;
    scopes: string[];
};

interface OrganizationMembersPageProps {
    handle: string;
}

/**
 * Checks if a set of scopes has full access.
 *
 * @param {string[]} scopes The scopes to check.
 * @return {boolean} Returns true if a user with the given scopes has full access.
 */
export function hasFullAccess(scopes: string[]): boolean {
    if (scopes.includes(Scope.ALL)) {
        return true;
    }
    const scopesExceptAll = Scopes.filter((scope) => scope.id !== Scope.ALL);
    return scopesExceptAll.every((scope) => scopes.includes(scope.id));
}

/**
 * Hook that returns the current path params
 * @param path The path(s) to match current path against
 */
export function usePathParams<Params extends string>(path: string | string[]) {
    const { pathname } = useLocation();

    const match = flatten([path])
        .map((name) => matchPath<Params, string>({ path: name, caseSensitive: true }, pathname))
        .find((val) => val);

    return match?.params;
}

export const OrganizationMembersPage = (props: OrganizationMembersPageProps) => {
    const [organization, setOrganization] = useState<MemberIdentity>();
    const [members, setMembers] = useState<MemberIdentity[]>([]);
    const [invitations, setInvitations] = useState<Invitation[]>([]);

    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);

    const canEdit = organization && hasAccess(Array.from(organization.scopes), SCOPE_IDENTITY_WRITE);

    const loadData = useCallback(async () => {
        let orgId;
        if (!organization) {
            const org = await api.organizations().getByHandleAsMember(props.handle);
            if (!org) {
                return;
            }
            setOrganization(org);
            orgId = org.identity.id;
        } else {
            orgId = organization.identity.id;
        }

        setMembers((await api.organizations().members(orgId, 'user')) ?? []);
        setInvitations((await api.organizations().invitations(orgId)) ?? []);
    }, [organization, props.handle]);

    function openInviteModal() {
        setIsInviteDialogOpen(true);
    }

    function closeInviteModal(didInviteUsers = false) {
        setIsInviteDialogOpen(false);
        if (didInviteUsers) {
            loadData().catch(() => null);
        }
    }

    const pathParams = usePathParams<'handle' | 'tab'>('/organizations/:handle/settings/members/:tab');
    const tab = pathParams?.tab ?? 'members';

    return (
        <TabPage title="Members" introduction={'Manage members in the organisation and their permissions'}>
            <Box sx={{ my: 2 }}>
                <Button
                    variant="contained"
                    size="large"
                    onClick={openInviteModal}
                    startIcon={<PersonAddIcon />}
                    disabled={!canEdit}
                >
                    Invite new member
                </Button>
            </Box>
            <Tabs value={tab} aria-label="Members tabs" sx={{ my: 3 }}>
                <Tab label="Members" sx={{ textTransform: 'none' }} value="members" to="" component={Link} />
                <Tab label="Invites" sx={{ textTransform: 'none' }} value="invites" to="invites" component={Link} />
            </Tabs>
            <Routes>
                <Route
                    path=""
                    element={
                        <MembersTab
                            organization={organization}
                            canEdit={canEdit}
                            members={members}
                            refetchData={loadData}
                        />
                    }
                />
                <Route
                    path="invites"
                    element={
                        <InvitesTab
                            organization={organization}
                            canEdit={canEdit}
                            invitations={invitations}
                            refetchData={loadData}
                        />
                    }
                />
            </Routes>

            <InviteMemberDialog
                key={isInviteDialogOpen ? 'open' : 'closed'} // Trigger reset of dialog state
                open={isInviteDialogOpen}
                onClose={closeInviteModal}
                organization={organization}
            />
        </TabPage>
    );
};
