import { useCallback, useState } from 'react';
import { SCOPE_IDENTITY_WRITE } from '../data/scopes';
import { hasAccess } from '@kapeta/web-microfrontend/browser';
import api from '../service/APIService';
import { MemberIdentity } from '@kapeta/ui-web-types';
import { ExtendedIdentity } from '../../.generated/entities/ExtendedIdentity';
import { IdentityIdentifier } from '../../.generated/entities/IdentityIdentifier';

type UseOrganizationSettingsProps = {
    handle: string;
};

const HANDLE = 'handle';

export const useOrganizationSettings = ({ handle }: UseOrganizationSettingsProps) => {
    const [organization, setOrganization] = useState<MemberIdentity & { identity: ExtendedIdentity }>();
    const canEdit = organization && hasAccess(organization.scopes, SCOPE_IDENTITY_WRITE);
    const handleIdentifier = organization?.identity?.identifiers.find((identifier) => identifier.type === HANDLE);

    /**
     * We use this function to update the handle in the organization when the user changes it. We
     * later call the api to refresh the organization. We avoid the old name being shown to the user
     * while the API is fetching the updated organization.
     */
    const setNewHandleInOrganization = useCallback(
        (newHandle: string) => {
            setOrganization((org) => {
                if (org) {
                    return {
                        ...org,
                        identity: {
                            ...org.identity,
                            identifiers: org.identity.identifiers.map((identifier) => {
                                if (identifier.type === HANDLE) {
                                    return {
                                        ...identifier,
                                        identifier: newHandle,
                                    } satisfies IdentityIdentifier;
                                }
                                return identifier;
                            }),
                        },
                    };
                }
                return org;
            });
        },
        [setOrganization]
    );

    const reloadOrganization = useCallback(
        async (newHandle?: string) => {
            const newOrganization = (await api.organizations().getByHandleAsMember(newHandle || handle)) as
                | (MemberIdentity & { identity: ExtendedIdentity })
                | null;
            setOrganization(newOrganization ?? undefined);
        },
        [handle]
    );

    return {
        organization,
        setNewHandleInOrganization,
        reloadOrganization,
        handleIdentifier,
        canEdit,
    };
};
