import React, { useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { FormContainer, AuthScopesField, KapDialog } from '@kapeta/ui-web-components';
import { IconButton, Box, Button } from '@mui/material';
import { Scopes } from '../../../../../data/scopes';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type SelectPermissionsFormProps = {
    scopes: string[];
    onClose: (scopes?: string[]) => void;
};

export default function SelectPermissionsForm({ scopes, onClose }: SelectPermissionsFormProps) {
    const initialFormValue = useMemo(() => ({ scopes }), [scopes]);
    const [selectedScopes, setSelectedScopes] = useState(initialFormValue.scopes);
    const hasSelectedScopes = selectedScopes.length > 0;

    return (
        <FormContainer
            initialValue={initialFormValue}
            onChange={(data) => {
                if (!isEqual(data.scopes, selectedScopes)) {
                    setSelectedScopes(data.scopes as string[]);
                }
            }}
            onSubmitData={(data) => onClose(data.scopes as string[])}
        >
            <KapDialog.Title sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 2 }}>
                <IconButton
                    aria-label="back"
                    onClick={() => {
                        onClose();
                    }}
                    sx={{ color: 'action.active', mx: -1 }}
                >
                    <ArrowBackIcon />
                </IconButton>
                Set permissions
            </KapDialog.Title>

            <KapDialog.Content dividers sx={{ borderBottom: 'none', overflowY: 'auto' }}>
                <Box sx={{ py: 2 }}>
                    <AuthScopesField scopes={Scopes} name="scopes" />
                </Box>
            </KapDialog.Content>

            <KapDialog.Actions>
                <Button type="submit" variant="contained" disabled={!hasSelectedScopes}>
                    Update
                </Button>
            </KapDialog.Actions>
        </FormContainer>
    );
}
