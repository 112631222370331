import React, { useState } from 'react';
import api from '../../../service/APIService';
import {
    AuthProviderIcon,
    AuthProviderIconProps,
    showToasty,
    SimpleLoader,
    ToastType,
    useConfirm,
} from '@kapeta/ui-web-components';

import './ConnectedAccountsPage.less';
import { TabPage } from '../../../layouts/TabPage';
import { Box, Button, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useCurrentUserId } from '../../../service/userHooks';
import { currentIdentity } from '@kapeta/web-microfrontend/browser';
import { useAsyncRetry } from 'react-use';
import { IdentityAuthentication } from '../../../../.generated/entities/IdentityAuthentication';

type DisconnectedAccount = {
    connected: false;
    name: null;
    email: null;
    organization: null;
    id: null;
};

type ConnectedAccount = {
    connected: true;
    name: AuthProviderIconProps['name'];
    email: string;
    organization: string;
    id: string;
};

const isConnectedAccount = (account: Account): account is ConnectedAccount => account.connected;

type Account = DisconnectedAccount | ConnectedAccount;

const createDisconnectedAccount = (): DisconnectedAccount => ({
    connected: false,
    name: null,
    email: null,
    organization: null,
    id: null,
});

const createConnectedAccount = (auth: IdentityAuthentication): ConnectedAccount => ({
    connected: true,
    name: auth.name as AuthProviderIconProps['name'],
    email: auth.data.email as string,
    organization: auth.data.organization as string,
    id: auth.id,
});

const prettyName: Record<AuthProviderIconProps['name'], string> = {
    google: 'Google',
    github: 'GitHub',
    microsoft: 'Microsoft',
};

export const ConnectedAccountsPage = () => {
    const currentUserId = useCurrentUserId();
    const [google, setGoogle] = useState<Account>(createDisconnectedAccount());
    const [github, setGithub] = useState<Account>(createDisconnectedAccount());
    const [microsoft, setMicrosoft] = useState<Account>(createDisconnectedAccount());

    const confirm = useConfirm();

    const connections = useAsyncRetry(async () => {
        const result = await api.connections().list(currentUserId);
        if (!result) {
            return;
        }

        result.forEach((auth) => {
            switch (auth.name) {
                case 'google':
                    setGoogle(createConnectedAccount(auth));
                    break;
                case 'github':
                    setGithub(createConnectedAccount(auth));
                    break;
                case 'microsoft':
                    setMicrosoft(createConnectedAccount(auth));
                    break;
                default: {
                    console.error(`Unknown auth provider: ${auth.name}`);
                }
            }
        });

        return result;
    }, [currentUserId]);

    function connect(name: AuthProviderIconProps['name']) {
        const url = `/external/connect/${encodeURIComponent(name)}/redirect`;
        const windowWidth = 550;
        const windowHeight = 400;
        const windowX = window.screenLeft + (screen.availWidth - windowWidth) / 2;
        const windowY = window.screenTop + 100;
        const win = window.open(
            url,
            'oauth2_flow',
            `toolbar=0,status=0,width=${windowWidth},height=${windowHeight},screenX=${windowX},screenY=${windowY}`
        );
        if (!win) {
            showToasty({
                title: 'Pop Up blocked!',
                type: ToastType.ALERT,
                message: 'Please allow popups to continue',
            });
            return;
        }

        let timer: NodeJS.Timeout | undefined = undefined;

        const handler = (e: Event) => {
            const evt = e as MessageEvent;
            if (evt.data !== 'oauth2_flow_done') {
                return;
            }

            connections.retry();
            window.removeEventListener('message', handler);
            clearTimeout(timer);
            win.close();
        };

        timer = setTimeout(() => {
            window.removeEventListener('message', handler);
            win.close();
        }, 300_000); // Make sure we remove the listener eventually

        window.addEventListener('message', handler);
    }

    async function disconnect(name: AuthProviderIconProps['name'], id: string) {
        if (
            await confirm({
                title: `Disconnect ${prettyName[name]}?`,
                content: `This will disconnect your ${prettyName[name]} account.`,
                confirmationText: 'Disconnect',
                confirmationButtonProps: { color: 'error' },
            })
        ) {
            try {
                await api.connections().disconnect(currentUserId, id);
                showToasty({
                    title: 'Success',
                    message: 'Connection was removed',
                    type: ToastType.SUCCESS,
                });

                switch (name) {
                    case 'google':
                        setGoogle(createDisconnectedAccount());
                        break;
                    case 'github':
                        setGithub(createDisconnectedAccount());
                        break;
                    case 'microsoft':
                        setMicrosoft(createDisconnectedAccount());
                        break;
                    default: {
                        name satisfies never;
                        throw new Error(`Can not disconnect unknown auth provider`);
                    }
                }
            } catch (error) {
                // Ignore
            }
        }
    }

    const renderConnector = (name: AuthProviderIconProps['name'], account: Account) => (
        <TableRow>
            <TableCell sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                <AuthProviderIcon name={name} size={40} />
                <Box>
                    <Typography variant="body1">{prettyName[name]}</Typography>
                    <Typography variant="body2" color="text.secondary">
                        {account.email ? `E-mail: ${account.email}` : 'Not connected'}
                    </Typography>
                </Box>
                {isConnectedAccount(account) ? (
                    <Button
                        data-kap-id={`connections-disconnect-${name}`}
                        onClick={() => disconnect(name, account.id)}
                        color="error"
                        variant="text"
                        sx={{ ml: 'auto' }}
                        aria-label={`Disconnect ${prettyName[name]}`}
                        title={`Disconnect ${prettyName[name]}`}
                        size="small"
                    >
                        Disconnect
                    </Button>
                ) : (
                    <Button
                        data-kap-id={`connections-connect-${name}`}
                        onClick={() => connect(name)}
                        color="primary"
                        variant="contained"
                        sx={{ ml: 'auto' }}
                        aria-label={`Connect ${prettyName[name]}`}
                        title={`Connect ${prettyName[name]}`}
                        // Can not connect if the current user is not the current identity
                        disabled={currentUserId !== currentIdentity()?.id}
                        size="small"
                    >
                        Connect
                    </Button>
                )}
            </TableCell>
        </TableRow>
    );

    return (
        <TabPage title="Connections" introduction={'Control your connected accounts below'}>
            <SimpleLoader loading={connections.loading}>
                <Table>
                    <TableBody>
                        {renderConnector('google', google)}
                        {renderConnector('github', github)}
                        {renderConnector('microsoft', microsoft)}
                    </TableBody>
                </Table>
            </SimpleLoader>
        </TabPage>
    );
};
