import React, { useState } from 'react';
import api from '../../../service/APIService';
import { saveAs } from 'file-saver';

import {
    FormButtons,
    FormField,
    FormData,
    showToasty,
    ToastType,
    SimpleLoader,
    AuthScopesField,
    useConfirm,
    KapFormDialog,
} from '@kapeta/ui-web-components';
import { hasAccess } from '@kapeta/web-microfrontend/browser';
import { SCOPE_IDENTITY_WRITE, Scopes } from '../../../data/scopes';
import { useAsync, useAsyncRetry } from 'react-use';
import { TabPage } from '../../../layouts/TabPage';
import {
    Button,
    Box,
    Table,
    TableBody,
    TableRow,
    IconButton,
    TableCell,
    List,
    ListItem,
    TableContainer,
    TableHead,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useCurrentUserId } from '../../../service/userHooks';
import { MemberIdentity } from '../../../../.generated/entities/MemberIdentity';

const DEFAULT_SCOPES = ['iam:read'];

interface ServiceAccount {
    id?: string;
    name: string;
    scopes: string[];
}

interface ServiceAccountsPageProps {
    handle?: string;
}

export const ServiceAccountsPage = (props: ServiceAccountsPageProps) => {
    const confirm = useConfirm();
    const currentUserId = useCurrentUserId();

    const [serviceAccountToEdit, setServiceAccountToEdit] = useState<ServiceAccount>();
    const [isServiceAccountModalOpen, setIsServiceAccountModalOpen] = useState(false);

    /**
     * This settings page is used in both user and organization contexts. Here we find out which
     * type of identity the handle refers to.
     */
    const primaryIdentity = useAsync(async (): Promise<MemberIdentity | null> => {
        let handle = props.handle;

        if (!handle) {
            const currentUser = await api.profile().getUser(currentUserId);
            if (!currentUser) {
                return null;
            }
            handle = currentUser.handle;
        }

        const publicIdentity = await api.identities('service').getPublic(handle);
        if (!publicIdentity) {
            return null;
        }

        if (publicIdentity.type === 'service') {
            return null;
        }

        if (publicIdentity.type === 'user') {
            const currentUser = await api.profile().getUser(currentUserId);
            if (!currentUser) {
                return null;
            }

            if (currentUser.id !== publicIdentity.id) {
                return null;
            }

            return {
                // TODO: we need to fix the different identity types. ExtendedIdentity is not a superset of MemberIdentity
                identity: currentUser,
                scopes: ['*'],
            };
        }

        if (publicIdentity.type === 'organization') {
            const org = await api.organizations().getByHandleAsMember(handle);
            if (!org) {
                return null;
            }

            return org;
        }

        return null;
    }, [currentUserId]);
    const canEdit = !!(
        primaryIdentity.value && hasAccess(Array.from(primaryIdentity.value.scopes), SCOPE_IDENTITY_WRITE)
    );

    const serviceAccounts = useAsyncRetry(async (): Promise<MemberIdentity[]> => {
        if (!primaryIdentity.value) {
            return [];
        }
        return (await api.serviceAccounts().list(primaryIdentity.value.identity.id)) as MemberIdentity[];
    }, [primaryIdentity.loading, primaryIdentity.value]);

    const saveServiceAccount = async (data: FormData) => {
        if (!primaryIdentity.value) {
            return;
        }

        if (data.id) {
            // Update permissions for service account
            try {
                await api
                    .organizations()
                    .join(primaryIdentity.value.identity.id, data.id as string, data.scopes as string[]);

                showToasty({
                    type: ToastType.SUCCESS,
                    message: 'Permissions was updated for service account',
                    title: 'Success',
                });
            } catch (error) {
                // Ignore
            }
        } else {
            // Create new service account
            try {
                await api.serviceAccounts().create({
                    name: data.name as string,
                    scopes: data.scopes as string[],
                    identityId: primaryIdentity.value.identity.id,
                });

                showToasty({
                    type: ToastType.SUCCESS,
                    message: 'Service account was created',
                    title: 'Success',
                });
            } catch (error) {
                // Ignore
            }
        }

        serviceAccounts.retry();

        setIsServiceAccountModalOpen(false);
    };

    const removeServiceAccount = async (id: string) => {
        if (!primaryIdentity.value) {
            return;
        }

        if (
            await confirm({
                title: 'Remove service account?',
                content: 'Action can not be undone',
                confirmationText: 'Remove',
                confirmationButtonProps: { color: 'error' },
            })
        ) {
            try {
                await api.serviceAccounts().remove(primaryIdentity.value.identity.id, id);

                showToasty({
                    type: ToastType.SUCCESS,
                    message: 'Service account was removed',
                    title: 'Success',
                });

                serviceAccounts.retry();
            } catch (error) {
                //
            }
        }
    };

    const downloadServiceAccount = async (id: string, name: string) => {
        if (!primaryIdentity.value) {
            return;
        }

        try {
            const orgId = primaryIdentity.value.identity.id;
            const jwt = await api.serviceAccounts().getJWTForServiceAccount(orgId, id);
            const blob = new Blob([JSON.stringify(jwt, null, 2)], { type: 'application/json' });
            saveAs(blob, `${name}.json`);
        } catch (error) {
            // Ignore
        }
    };

    function closeModal() {
        setIsServiceAccountModalOpen(false);
        setServiceAccountToEdit(undefined);
    }

    function openModal(account?: MemberIdentity) {
        setIsServiceAccountModalOpen(true);
        setServiceAccountToEdit({
            scopes: account?.scopes ? Array.from(account?.scopes) : DEFAULT_SCOPES,
            name: account?.identity.name || '',
            id: account?.identity.id,
        });
    }

    const isNew = !serviceAccountToEdit?.id;

    return (
        <TabPage
            title="Service Accounts"
            introduction={'Use service accounts when you need machine-to-machine authentication.'}
        >
            <SimpleLoader
                loading={primaryIdentity.loading || serviceAccounts.loading}
                text={'Loading service accounts...'}
            >
                <TableContainer sx={{ mb: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Scopes</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(serviceAccounts.value || []).map((serviceAccount) => (
                                <TableRow key={serviceAccount.identity.id} sx={{ verticalAlign: 'top' }}>
                                    <TableCell>{serviceAccount.identity.name}</TableCell>
                                    <TableCell>
                                        <List sx={{ p: 0 }}>
                                            {[...serviceAccount.scopes].sort().map((s) => (
                                                <ListItem key={s} sx={{ p: 0, fontSize: '12px', lineHeight: '18px' }}>
                                                    {s}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </TableCell>
                                    <TableCell>
                                        <Box display="flex" flexDirection="row" gap={3} marginLeft="auto">
                                            <IconButton
                                                data-kap-id="download-service-account"
                                                aria-label="Download service account"
                                                title={'Download service account'}
                                                color="inherit"
                                                size="small"
                                                onClick={() =>
                                                    downloadServiceAccount(
                                                        serviceAccount.identity.id,
                                                        serviceAccount.identity.name
                                                    )
                                                }
                                            >
                                                <DownloadIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton
                                                data-kap-id="edit-service-account"
                                                aria-label="Edit service account"
                                                title={'Edit service account'}
                                                color="primary"
                                                size="small"
                                                onClick={() => openModal(serviceAccount)}
                                            >
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton
                                                data-kap-id="remove-service-account"
                                                aria-label="Remove service account"
                                                title={'Remove service account'}
                                                color="error"
                                                size="small"
                                                onClick={() => removeServiceAccount(serviceAccount.identity.id)}
                                            >
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {canEdit && (
                    <Button
                        color={'secondary'}
                        variant="contained"
                        onClick={() => openModal()}
                        sx={{ width: 230 }}
                        data-kap-id="create-service-account"
                    >
                        Create service account
                    </Button>
                )}
            </SimpleLoader>

            <KapFormDialog
                title={isNew ? 'Create Service Account' : `Update ${serviceAccountToEdit?.name}`}
                open={isServiceAccountModalOpen}
                onClose={() => closeModal()}
                initialValue={serviceAccountToEdit}
                onSubmitData={(data: FormData) => saveServiceAccount(data)}
                actions={
                    <FormButtons addWrapperDiv={false}>
                        <Button
                            onClick={closeModal}
                            variant="text"
                            color="inherit"
                            data-kap-id="service-account-form-cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            data-kap-id="service-account-form-submit"
                        >
                            {isNew ? 'Create' : 'Update'}
                        </Button>
                    </FormButtons>
                }
            >
                <FormField
                    name={'name'}
                    label={'Name'}
                    variant="outlined"
                    disabled={!isNew}
                    help={'Name your service account'}
                    validation={['required']}
                />

                <Box sx={{ mt: 2 }} />

                <AuthScopesField scopes={Scopes} name={'scopes'} />
            </KapFormDialog>
        </TabPage>
    );
};
