import { useEffect, useCallback, useState, useMemo } from 'react';
import api from '../service/APIService';
import { useCurrentUserId } from '../service/userHooks';
import { useAsyncRetry } from 'react-use';
import { ExtendedIdentity } from '../../.generated/entities/ExtendedIdentity';
import { IdentityIdentifier } from '../../.generated/entities/IdentityIdentifier';

const ignore = () => undefined;

const HANDLE = 'handle';
const EMAIL = 'email';
const PHONE = 'phone';

export const useProfileSettings = () => {
    const currentUserId = useCurrentUserId();

    const profileResult = useAsyncRetry(async () => {
        return await api.profile().getUser(currentUserId);
    }, [currentUserId]);

    const identifierResult = useAsyncRetry(async () => {
        const identifiers = await api.identifiers().list(currentUserId);
        return identifiers ?? [];
    }, [currentUserId]);

    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState<ExtendedIdentity>();
    const [identifiers, setIdentifiers] = useState<IdentityIdentifier[]>([]);

    useEffect(() => {
        setProfile(profileResult.value ?? undefined);
    }, [profileResult.value]);

    useEffect(() => {
        setIdentifiers(identifierResult.value ?? []);
    }, [identifierResult.value]);

    useEffect(() => {
        if (!loading) {
            return;
        }

        if (profileResult.loading || identifierResult.loading) {
            return;
        }

        setLoading(false);
    }, [loading, identifierResult.loading, profileResult.loading]);

    const emails = identifiers.filter((identifier) => identifier.type === EMAIL);
    const phones = identifiers.filter((identifier) => identifier.type === PHONE);
    const handles = identifiers.filter((identifier) => identifier.type === HANDLE);
    const handle = handles.length > 0 ? handles[0] : undefined;

    return useMemo(
        () => ({
            profile,
            loading,
            identifiers: {
                emails,
                phones,
                handle,
            },
            reloadProfile: () => profileResult.retry(),
            reloadIdentifiers: () => identifierResult.retry(),
        }),
        [currentUserId, loading, profile, identifiers]
    );
};
