import React, { useMemo } from 'react';
import { InlineEditFormField } from './InlineEditFormField';
import { BoxProps } from '@mui/material';
import { showToasty, ToastType } from '@kapeta/ui-web-components';
import api from '../service/APIService';
import { ExtendedIdentity } from '../../.generated/entities/ExtendedIdentity';

type EditOrganizationNameFormProps = {
    identity: ExtendedIdentity | undefined;
    onNameChange?: (newName: string) => void;
    disabled?: boolean;
} & BoxProps;

const ignore = () => null;

export const EditOrganizationNameForm = ({
    identity,
    onNameChange,
    disabled = false,
    ...boxProps
}: EditOrganizationNameFormProps) => {
    const updateName = async (newName: string) => {
        if (!newName || !identity) {
            return;
        }

        try {
            await api.organizations().update({ id: identity.id, name: newName.trim() });
            showToasty({
                type: ToastType.SUCCESS,
                message: 'Organization name was updated',
                title: 'Success',
            });
            onNameChange?.(newName);
        } catch (error) {
            // ignore
        }
    };

    return (
        <InlineEditFormField
            {...boxProps}
            formContainerProps={{
                initialValue: useMemo(
                    () => ({
                        name: identity?.name || '',
                    }),
                    [identity?.name]
                ),
                onSubmitData: (data: any) => updateName(data.name as string),
            }}
            formFieldProps={{
                label: 'Name',
                name: 'name',
                validation: ['required'],
                disabled,
            }}
        />
    );
};
