import React, { useEffect, useMemo, useState } from 'react';
import api from '../../../service/APIService';
import 'unibabel/unibabel.base32'; // Adds itself to "window"
import { FormContainer, SimpleLoader, showToasty, ToastType, OTPInput, FormButtons } from '@kapeta/ui-web-components';
import { Box, Button, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { TabPage } from '../../../layouts/TabPage';
import { useCurrentUser, useCurrentUserId } from '../../../service/userHooks';
import { useAsyncRetry } from 'react-use';

const ISSUER = 'Kapeta.com';

function createKey() {
    const bin = window.crypto.getRandomValues(new Uint8Array(20));

    //bufferToBase32 from unibabel
    // @ts-ignore
    const base32 = window.bufferToBase32(bin).replace(/=/g, '');

    return base32
        .toLowerCase()
        .replace(/(\w{4})/g, '$1 ')
        .trim()
        .toUpperCase();
}

export const OTPConfigurePage = () => {
    const currentUserId = useCurrentUserId();
    const [twoFactorCode, setTwoFactorCode] = useState('');
    const canSubmit = twoFactorCode.length === 6;
    const key = useMemo(() => createKey(), [currentUserId]);
    const secret = key.replace(/[\s._\-]+/g, '').toUpperCase();
    const profile = useCurrentUser();

    const keyUri = useMemo(() => {
        if (!profile.value?.handle) {
            return undefined;
        }
        return `otpauth://totp/${encodeURI(ISSUER)}:${encodeURI(
            profile.value?.handle
        )}?secret=${secret}&issuer=${encodeURIComponent(ISSUER)}`;
    }, [profile.value?.handle, secret, currentUserId]);

    const authentication = useAsyncRetry(async () => {
        const out = await api.otp().get(currentUserId);
        if (out && out.length > 0) {
            return out[0];
        }
        return undefined;
    }, [currentUserId]);

    const isTwoFactorEnabled = Boolean(authentication.value?.id);

    // Use Google Charts to generate the QR image
    const qrUrl = keyUri
        ? `https://chart.googleapis.com/chart?chs=200x200&chld=L|0&cht=qr&chl=${encodeURIComponent(keyUri)}`
        : undefined;

    async function configureOTP() {
        try {
            await api.otp().configure(currentUserId, { code: twoFactorCode, secret });

            await authentication.retry();
        } catch (e) {
            showToasty({
                title: 'Error',
                message: 'Configuring 2-Factor Authentication failed',
                type: ToastType.DANGER,
            });
        }
    }

    async function disableOTP() {
        if (!authentication.value?.id) {
            return;
        }
        try {
            await api.otp().disable(currentUserId, authentication.value.id);
            await authentication.retry();
        } catch (e) {
            showToasty({
                title: 'Error',
                message: 'Disabling 2-Factor Authentication failed',
                type: ToastType.DANGER,
            });
        }
    }

    return (
        <TabPage title="2-Factor Authentication" introduction={'Set up 2-factor authentication for your user'}>
            <SimpleLoader loading={authentication.loading && !qrUrl}>
                {isTwoFactorEnabled && (
                    <>
                        <Box display="flex" alignItems="center" gap={1}>
                            <CheckIcon color="success" fontSize="inherit" />
                            <Typography>2FA Enabled</Typography>
                        </Box>
                        <Button variant="contained" color="error" onClick={disableOTP} sx={{ mt: 2 }}>
                            Disable
                        </Button>
                    </>
                )}

                {!isTwoFactorEnabled && (
                    <>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            Scan the QR code below with your authenticator app
                        </Typography>

                        <img src={qrUrl} alt={'QR Code'} width={200} height={200} style={{ marginLeft: '-6px' }} />

                        <Box sx={{ mt: 1 }}>
                            <Box sx={{ backgroundColor: '#f1f1f1', p: 1, display: 'inline-block' }}>{key}</Box>
                        </Box>

                        <FormContainer onSubmit={configureOTP}>
                            <Box sx={{ mt: 3 }}>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    Verify your 2FA configuration by entering the code here
                                </Typography>
                                <OTPInput value={twoFactorCode} onChange={setTwoFactorCode} />
                            </Box>

                            <Box sx={{ mt: 3 }}>
                                <FormButtons addWrapperDiv={false}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={!canSubmit}
                                        data-kap-id="2fa-submit-button"
                                    >
                                        Configure
                                    </Button>
                                </FormButtons>
                            </Box>
                        </FormContainer>
                    </>
                )}
            </SimpleLoader>
        </TabPage>
    );
};
