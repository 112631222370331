import React, { useState } from 'react';
import api from '../../../../service/APIService';
import { CircularProgress, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ToastType, showToasty, useConfirm } from '@kapeta/ui-web-components';
import { Invitation } from '../../../../../.generated/entities/Invitation';
import { MemberIdentity } from '../../../../../.generated/entities/MemberIdentity';
import { useInvitationsClient } from '../../../../.generated/clients/InvitationsClient';

type EditInvitationButtonProps = {
    organization?: MemberIdentity;
    invitation: Invitation;
    onRevoke?: () => void;
};

export default function EditInvitationButton({ organization, invitation, onRevoke }: EditInvitationButtonProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const invitationsClient = useInvitationsClient();

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const buttonId = `more-button-${invitation.id}`;
    const menuId = `edit-invitation-menu-${invitation.id}`;

    const confirm = useConfirm();
    const [isRevoking, setIsRevoking] = useState(false);
    async function revokeInvitation(inv: Invitation) {
        if (
            await confirm({
                title: 'Revoke invitation?',
                content: (
                    <Typography variant="body1">
                        Are you sure you want to revoke the invitation for{' '}
                        <Typography variant="subtitle2" component="span">
                            {inv.inviteeEmail}
                        </Typography>{' '}
                    </Typography>
                ),
                confirmationText: 'Yes, revoke invitation',
                confirmationButtonProps: { color: 'error' },
                cancellationText: 'No, cancel',
            })
        ) {
            try {
                setIsRevoking(true);

                organization && (await invitationsClient.revokeInvitation(inv.id));

                showToasty({
                    type: ToastType.SUCCESS,
                    message: `Invitaion for ${inv.inviteeEmail} has been revoked`,
                    title: 'Success',
                });

                if (onRevoke) {
                    onRevoke();
                }
            } catch (_error) {
                showToasty({
                    type: ToastType.DANGER,
                    message: `Failed to revoke invitation for ${inv.inviteeEmail}`,
                    title: 'Error',
                });
            } finally {
                setIsRevoking(false);
            }
        }
    }

    const [isResending, setIsResending] = useState(false);
    async function resendInvitation(inv: Invitation) {
        if (
            await confirm({
                title: 'Resend invitation?',
                content: (
                    <Typography variant="body1">
                        Do you want to resend the invitation to{' '}
                        <Typography variant="subtitle2" component="span">
                            {inv.inviteeEmail}
                        </Typography>{' '}
                    </Typography>
                ),
                confirmationText: 'Yes, resend',
                cancellationText: 'Cancel',
            })
        ) {
            try {
                setIsResending(true);

                organization && (await invitationsClient.resendInvitation(inv.id));

                showToasty({
                    type: ToastType.SUCCESS,
                    message: `Invitaion for ${inv.inviteeEmail} has been resent`,
                    title: 'Success',
                });

                if (onRevoke) {
                    onRevoke();
                }
            } catch (_error) {
                showToasty({
                    type: ToastType.DANGER,
                    message: `Failed to resend invitation for ${inv.inviteeEmail}`,
                    title: 'Error',
                });
            } finally {
                setIsResending(false);
            }
        }
    }

    return (
        <>
            <IconButton
                aria-label="More"
                id={buttonId}
                aria-controls={isOpen ? menuId : undefined}
                aria-expanded={isOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                disabled={isRevoking}
            >
                {isRevoking || isResending ? <CircularProgress size="24" color="inherit" /> : <MoreHorizIcon />}
            </IconButton>
            <Menu
                id={menuId}
                MenuListProps={{ 'aria-labelledby': buttonId }}
                anchorEl={anchorEl}
                open={isOpen}
                onClose={closeMenu}
            >
                <MenuItem
                    onClick={() => {
                        revokeInvitation(invitation);
                        closeMenu();
                    }}
                >
                    <ListItemIcon>
                        <DeleteIcon fontSize="inherit" />
                    </ListItemIcon>
                    Revoke invitation
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        resendInvitation(invitation);
                        closeMenu();
                    }}
                >
                    <ListItemIcon>
                        <SendIcon fontSize="inherit" />
                    </ListItemIcon>
                    Resend invitation
                </MenuItem>
            </Menu>
        </>
    );
}
