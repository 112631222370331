import React from 'react';
import { ProfileLayout } from '../../layouts/ProfileLayout';
import './PublicProfileView.less';
import { Page } from '@kapeta/ui-web-components/src/special/Page';
import { Detail, DetailRowValue, DetailSize } from '@kapeta/ui-web-components';
import { useParams } from 'react-router-dom';

export interface Props {}

export const PublicProfileView = (props: Props) => {
    const { handle = '' } = useParams();
    return (
        <ProfileLayout key={handle}>
            <Page title={handle}>
                <Detail data={{ handle }} editable={false} size={DetailSize.FULL}>
                    <DetailRowValue label={'Handle'} name={'handle'} />
                    <DetailRowValue label={'Type'} name={'type'} />
                </Detail>
            </Page>
        </ProfileLayout>
    );
};
