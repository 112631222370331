import React, { useMemo, useState } from 'react';
import { KapFormDialog, FormButtons, FormField, FormFieldType, AuthScopesField } from '@kapeta/ui-web-components';
import { Button, Box } from '@mui/material';
import { Scopes } from '../../../../data/scopes';
import { Client } from '../../../../../.generated/entities/Client';

const GRANT_TYPES = {
    authorization_code: 'Authorization Code',
    client_credentials: 'Client Credentials',
    'urn:ietf:params:oauth:grant-type:device_code': 'Device Code',
    'urn:oauth:grant-type:external-oauth2': 'External OAuth2',
    'urn:ietf:params:oauth:grant-type:jwt-bearer': 'JWT',
    password: 'Password',
    refresh_token: 'Refresh Token',
    'urn:oauth:grant-type:shared-secret-refresh': 'Shared Secret',
} as const;

type GrantType = keyof typeof GRANT_TYPES;

const needsSecret = (grantType: GrantType) => {
    switch (grantType) {
        case 'authorization_code':
        case 'client_credentials':
        case 'refresh_token':
            return true;
        default:
            return false;
    }
};

type FormData = {
    [key: string]: unknown;
};

type Oauth2ClientDialogProps = {
    open: boolean;
    client?: Client;
    onClose: () => void;
    onSave: (client: FormData) => Promise<void>;
};

export const Oauth2ClientDialog = (props: Oauth2ClientDialogProps) => {
    const { open, client, onClose, onSave } = props;

    const isEditing = Boolean(client);

    const initialValue = useMemo(
        () =>
            client || {
                name: '',
                scopes: [],
                secret: crypto.randomUUID(),
                allowedGrantTypes: [],
            },
        [client]
    );

    const [formData, setFormData] = useState<FormData>();

    const isSecretRequired: boolean =
        (formData &&
            'allowedGrantTypes' in formData &&
            (formData.allowedGrantTypes as GrantType[]).some(needsSecret)) ||
        false;

    return (
        <KapFormDialog
            open={open}
            onClose={onClose}
            initialValue={initialValue}
            title={client ? 'Edit client' : 'Add client'}
            onChange={setFormData}
            onSubmitData={onSave}
            actions={
                <FormButtons addWrapperDiv={false}>
                    <Button variant="text" color="inherit" onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button variant="contained" color={'primary'} type={'submit'}>
                        Save
                    </Button>
                </FormButtons>
            }
        >
            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                sx={{
                    '& .MuiFormControl-root': { my: 0 }, // Remove hardcoded margin from formcontrols
                }}
            >
                <FormField variant="outlined" name={'name'} label={'Name'} validation={['required']} />

                <FormField
                    variant="outlined"
                    name={'allowedGrantTypes'}
                    label={'Allowed Grant Types'}
                    type={FormFieldType.ENUM_MULTI}
                    options={GRANT_TYPES}
                    validation={['required']}
                    disabled={isEditing}
                />

                <FormField
                    variant="outlined"
                    name={'secret'}
                    label={'Secret'}
                    validation={isSecretRequired ? ['required'] : undefined}
                    disabled={isEditing}
                />

                <AuthScopesField scopes={Scopes} name={'scopes'} />
            </Box>
        </KapFormDialog>
    );
};
