//
// GENERATED SOURCE - DO NOT EDIT
//

import { useMemo } from 'react';
import { RestClient } from '@kapeta/sdk-web-rest-client';
import { RestClientRequest } from '@kapeta/sdk-rest';
import { TypedRequest } from '../../../.generated/entities/TypedRequest';
import { IdentityAuthentication } from '../../../.generated/entities/IdentityAuthentication';

export type ConfigureIdentityAuthenticationClient = (
    client: IdentityAuthenticationClient
) => IdentityAuthenticationClient;

/**
 * Creates a new IdentityAuthenticationClient for React components.
 * The client is configured with the provided configuration function.
 *
 * It is wrapped in a useMemo hook to ensure that the client is only created once.
 */
export const useIdentityAuthenticationClient = (
    configure?: ConfigureIdentityAuthenticationClient
): IdentityAuthenticationClient => {
    return useMemo(() => {
        const client = new IdentityAuthenticationClient();
        if (configure) {
            return configure(client);
        }
        return client;
    }, [configure]);
};

/**
 * A client for the IdentityAuthentication API.
 * Use the useIdentityAuthenticationClient hook to create a client in React.
 *
 * Use the IdentityAuthenticationClient directly in other contexts.
 */
export class IdentityAuthenticationClient extends RestClient {
    constructor() {
        super('api/rest/identityAuthentication');
    }

    /**
     * HTTP: POST /api/rest/identityauthentication/identities/{identityId}/authentications/create
     */
    async createAuthentications(identityId: string, typedRequest: TypedRequest): Promise<void> {
        await this.$execute<void>('POST', '/identities/{identityId}/authentications/create', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'typedRequest', value: typedRequest, transport: 'BODY', typeName: 'TypedRequest' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/identityauthentication/identities/{identityId}/authentications/create
     */
    createAuthenticationsRequest(identityId: string, typedRequest: TypedRequest): RestClientRequest<void> {
        return this.$create<void>('POST', '/identities/{identityId}/authentications/create', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'typedRequest', value: typedRequest, transport: 'BODY', typeName: 'TypedRequest' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identityauthentication/identities/{identityId}/authentications
     */
    async getAuthentications(identityId: string, type?: string): Promise<IdentityAuthentication[] | null> {
        const result = await this.$execute<IdentityAuthentication[]>(
            'GET',
            '/identities/{identityId}/authentications',
            [
                { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
                { name: 'type', value: type, transport: 'QUERY', typeName: 'string' },
            ]
        );

        if (result === null) {
            return null;
        }
        return result as IdentityAuthentication[];
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identityauthentication/identities/{identityId}/authentications
     */
    getAuthenticationsRequest(identityId: string, type?: string): RestClientRequest<IdentityAuthentication[] | null> {
        return this.$create<IdentityAuthentication[]>('GET', '/identities/{identityId}/authentications', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'type', value: type, transport: 'QUERY', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: DELETE /api/rest/identityauthentication/identities/{identityId}/authentications/{authenticationId}
     */
    async deleteAuthentications(identityId: string, authenticationId: string): Promise<void> {
        await this.$execute<void>('DELETE', '/identities/{identityId}/authentications/{authenticationId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'authenticationId', value: authenticationId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: DELETE /api/rest/identityauthentication/identities/{identityId}/authentications/{authenticationId}
     */
    deleteAuthenticationsRequest(identityId: string, authenticationId: string): RestClientRequest<void> {
        return this.$create<void>('DELETE', '/identities/{identityId}/authentications/{authenticationId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'authenticationId', value: authenticationId, transport: 'PATH', typeName: 'string' },
        ]);
    }
}
