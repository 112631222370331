import { useAsyncRetry } from 'react-use';
import api from './APIService';
import { currentIdentity, useCurrentContext, useOnContextChange } from '@kapeta/web-microfrontend/browser';
import { useState } from 'react';

export const useCurrentUserId = () => {
    const currentContext = useCurrentContext();
    const [currentUserId, setCurrentUserId] = useState<string>((): string => {
        if (currentContext?.type === 'user') {
            return currentContext.id;
        }

        if (window.Context?.current?.type === 'user') {
            return window.Context.current.id;
        }

        return currentIdentity()?.id!;
    });

    useOnContextChange((context) => {
        if (context.type === 'user') {
            setCurrentUserId(context.id);
        } else {
            setCurrentUserId(currentIdentity()?.id!);
        }
    });

    return currentUserId;
};

export const useCurrentUser = () => {
    const currentUserId = useCurrentUserId();
    return useAsyncRetry(async () => {
        return await api.profile().getUser(currentUserId);
    }, [currentUserId]);
};
