import React from 'react';
import { CheckAccess } from '@kapeta/web-microfrontend/src/browser/components/CheckAccess';
import { Scope } from '@kapeta/web-microfrontend/src/browser/utils/access';
import { Stack, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { EditSystemScopesForm } from './EditSystemScopesForm';
import { EditSingleValueForm } from './EditSingleValueForm';
import { grey } from '@mui/material/colors';
import { useDeploymentConfigClient } from '../.generated/clients/DeploymentConfigClient';
import { usePaymentsClient } from '../.generated/clients/PaymentsClient';

const SYSTEM_SPEND_THRESHOLD_SCOPE = 'system:https://auth.kapeta.com/scopes/subscriptions:spend-threshold';
const DEFAULT_SPEND_THRESHOLD = 100;
const DEFAULT_DEPLOYMENTS_QUOTA = 5;

interface Props {
    handle: string;
    identityId: string;
    identityType: string;
}

export const SystemAdminForms = (props: Props) => {
    const deploymentConfigClient = useDeploymentConfigClient();
    const paymentsClient = usePaymentsClient();
    return (
        <CheckAccess
            requireAnyScope
            requiredScopes={[Scope.SCOPE_SYSTEM_SCOPES_ADMIN, Scope.DEPLOYMENT_ADMIN, Scope.SUBSCRIPTION_ADMIN]}
        >
            <Stack p={2} bgcolor={grey[100]} direction={'column'} gap={2}>
                {props.identityType === 'user' && (
                    <CheckAccess requiredScopes={Scope.SCOPE_SYSTEM_SCOPES_ADMIN}>
                        <Stack direction="column" gap={2}>
                            <Typography variant="body1" component="h3">
                                <LockIcon /> System Scopes
                            </Typography>

                            <EditSystemScopesForm identityId={props.identityId} />
                        </Stack>
                    </CheckAccess>
                )}

                <CheckAccess requiredScopes={Scope.DEPLOYMENT_ADMIN}>
                    <Stack direction="column" gap={2}>
                        <Typography variant="body1" component="h3">
                            <LockIcon /> Deployments
                        </Typography>
                        <EditSingleValueForm
                            defaultValue={DEFAULT_DEPLOYMENTS_QUOTA}
                            label={'Max. Deployments'}
                            getter={async () => {
                                const result = await deploymentConfigClient.getMaxdeployment(props.handle);
                                return result?.maxDeployments ?? DEFAULT_DEPLOYMENTS_QUOTA;
                            }}
                            setter={async (value) => {
                                await deploymentConfigClient.setMaxdeployment(props.handle, value);
                            }}
                        />
                    </Stack>
                </CheckAccess>

                <CheckAccess requiredScopes={SYSTEM_SPEND_THRESHOLD_SCOPE}>
                    <Stack direction="column" gap={2}>
                        <Typography variant="body1" component="h3">
                            <LockIcon /> Spend Threshold
                        </Typography>
                        <EditSingleValueForm
                            defaultValue={DEFAULT_SPEND_THRESHOLD}
                            label={'Spend Threshold in USD'}
                            getter={async () => {
                                const result = await paymentsClient.getSpendThreshold(props.handle);
                                return result?.threshold ?? DEFAULT_SPEND_THRESHOLD;
                            }}
                            setter={async (value) => {
                                await paymentsClient.setSpendThreshold(props.handle, {
                                    threshold: value,
                                });
                            }}
                        />
                    </Stack>
                </CheckAccess>
            </Stack>
        </CheckAccess>
    );
};
