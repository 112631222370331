import React, { useState } from 'react';
import { Scope } from '@kapeta/web-microfrontend/browser';
import SelectPermissionsForm from './SelectPermissionsForm';
import SelectUsersForm from './SelectUsersForm';
import { KapDialog } from '@kapeta/ui-web-components';
import { MemberIdentity } from '../../../../../../.generated/entities/MemberIdentity';

export type UnknownUser = {
    label: string;
    valid: boolean;
    email: string;
};

export type KnownUser = {
    label: string;
    valid: boolean;
    inviteeIdentityId: string;
    handle: string;
};

export type UserToInvite = UnknownUser | KnownUser;

type InviteMemberDialogProps = {
    open: boolean;
    onClose: (didInviteUsers?: boolean) => void;
    organization?: MemberIdentity;
};

export function InviteMemberDialog({ open, onClose, organization }: InviteMemberDialogProps) {
    /**
     * The dialog has two modes: 'users' and 'scopes', when the user is in the users mode, they can add users to the
     * invite list, when they are in the scopes mode, they can select the scopes for the invitees.
     */
    const [dialogContentMode, setDialogContentMode] = useState<'users' | 'scopes'>('users');
    const isUsersMode = dialogContentMode === 'users';
    const isScopesMode = dialogContentMode === 'scopes';

    /**
     * We keep track of the users and the scopes in this parent component, so that we can pass them to the
     * SelectUsersForm and SelectPermissionsForm components.
     */
    const [selectedUsers, setSelectedUsers] = useState<UserToInvite[]>([]);
    const [selectedScopes, setSelectedScopes] = useState<string[]>([Scope.ALL]);

    return (
        <KapDialog open={open} onClose={() => onClose(false)}>
            {isUsersMode && (
                <SelectUsersForm
                    organization={organization}
                    users={selectedUsers}
                    setUsers={setSelectedUsers}
                    scopes={selectedScopes}
                    onClose={onClose}
                    changeToScopesMode={() => setDialogContentMode('scopes')}
                />
            )}

            {isScopesMode && (
                <SelectPermissionsForm
                    scopes={selectedScopes}
                    onClose={(newScopes) => {
                        if (newScopes) {
                            setSelectedScopes(newScopes);
                        }
                        setDialogContentMode('users');
                    }}
                />
            )}
        </KapDialog>
    );
}
