import React, { useState } from 'react';
import api from '../../../../service/APIService';
import { Member } from './OrganizationMembersPage';
import { CircularProgress, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ToastType, showToasty, useConfirm } from '@kapeta/ui-web-components';
import { MemberIdentity } from '../../../../../.generated/entities/MemberIdentity';

type EditMemberButtonProps = {
    organization?: MemberIdentity;
    member: Member;
    editMember: (member: Member) => void;
    onRemove?: () => void;
};

export default function EditMemberButton({ organization, member, editMember, onRemove }: EditMemberButtonProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const userProfileId = (window.Context?.profile?.sub as string) || '';

    const isOpen = Boolean(anchorEl);

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const buttonId = `more-button-${member.id}`;
    const menuId = `edit-member-menu-${member.id}`;

    const confirm = useConfirm();
    const [isRemoving, setIsRemoving] = useState(false);
    async function removeMember(memberToRemove: Member) {
        if (
            await confirm({
                title: 'Remove member?',
                content: (
                    <Typography variant="body1">
                        Are you sure you want to permanently remove{' '}
                        <Typography variant="subtitle2" component="span">
                            {memberToRemove.name}
                        </Typography>{' '}
                        from your team?
                        <br />
                        <br />
                        This action can not be undone.
                    </Typography>
                ),
                confirmationText: 'Yes, remove member',
                confirmationButtonProps: { color: 'error' },
                cancellationText: 'No, cancel',
            })
        ) {
            setIsRemoving(true);
            try {
                organization && (await api.organizations().leave(organization.identity.id, memberToRemove.id));

                showToasty({
                    type: ToastType.SUCCESS,
                    message: `${memberToRemove.name} has been removed`,
                    title: 'Success',
                });

                if (member.id === userProfileId) {
                    // If the user is removing themselves, reload the page
                    window.location.reload();
                }
            } catch (_error) {
                showToasty({
                    type: ToastType.DANGER,
                    message: `Failed to remove ${memberToRemove.name}`,
                    title: 'Error',
                });
            } finally {
                setIsRemoving(false);
            }

            if (onRemove) {
                onRemove();
            }
        }
    }

    return (
        <>
            <IconButton
                aria-label="More"
                id={buttonId}
                aria-controls={isOpen ? menuId : undefined}
                aria-expanded={isOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                disabled={isRemoving}
            >
                {isRemoving ? <CircularProgress size="24" color="inherit" /> : <MoreHorizIcon />}
            </IconButton>
            <Menu
                id={menuId}
                MenuListProps={{ 'aria-labelledby': buttonId }}
                anchorEl={anchorEl}
                open={isOpen}
                onClose={closeMenu}
            >
                <MenuItem
                    onClick={() => {
                        removeMember(member);
                        closeMenu();
                    }}
                >
                    <ListItemIcon>
                        <DeleteIcon fontSize="inherit" />
                    </ListItemIcon>
                    Remove member
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        editMember(member);
                        closeMenu();
                    }}
                >
                    <ListItemIcon>
                        <EditIcon fontSize="inherit" />
                    </ListItemIcon>
                    Edit permissions
                </MenuItem>
            </Menu>
        </>
    );
}
