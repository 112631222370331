//
// GENERATED SOURCE - DO NOT EDIT
//

import { useMemo } from 'react';
import { RestClient } from '@kapeta/sdk-web-rest-client';
import { RestClientRequest } from '@kapeta/sdk-rest';
import { PasswordChangeRequest } from '../../../.generated/entities/PasswordChangeRequest';
import { ResetPasswordRequest } from '../../../.generated/entities/ResetPasswordRequest';
import { AccessTokenResponse } from '../../../.generated/entities/AccessTokenResponse';
import { ActivateUserIdentityRequest } from '../../../.generated/entities/ActivateUserIdentityRequest';
import { UserRegistrationDetails } from '../../../.generated/entities/UserRegistrationDetails';

export type ConfigureUserClient = (client: UserClient) => UserClient;

/**
 * Creates a new UserClient for React components.
 * The client is configured with the provided configuration function.
 *
 * It is wrapped in a useMemo hook to ensure that the client is only created once.
 */
export const useUserClient = (configure?: ConfigureUserClient): UserClient => {
    return useMemo(() => {
        const client = new UserClient();
        if (configure) {
            return configure(client);
        }
        return client;
    }, [configure]);
};

/**
 * A client for the User API.
 * Use the useUserClient hook to create a client in React.
 *
 * Use the UserClient directly in other contexts.
 */
export class UserClient extends RestClient {
    constructor() {
        super('api/rest/user');
    }

    /**
     * HTTP: PUT /api/rest/user/identities/users/{userId}/password
     */
    async updatePassword(userId: string, passwordChangeRequest: PasswordChangeRequest): Promise<void> {
        await this.$execute<void>('PUT', '/identities/users/{userId}/password', [
            { name: 'userId', value: userId, transport: 'PATH', typeName: 'string' },
            {
                name: 'passwordChangeRequest',
                value: passwordChangeRequest,
                transport: 'BODY',
                typeName: 'PasswordChangeRequest',
            },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: PUT /api/rest/user/identities/users/{userId}/password
     */
    updatePasswordRequest(userId: string, passwordChangeRequest: PasswordChangeRequest): RestClientRequest<void> {
        return this.$create<void>('PUT', '/identities/users/{userId}/password', [
            { name: 'userId', value: userId, transport: 'PATH', typeName: 'string' },
            {
                name: 'passwordChangeRequest',
                value: passwordChangeRequest,
                transport: 'BODY',
                typeName: 'PasswordChangeRequest',
            },
        ]);
    }

    /**
     * HTTP: PUT /api/rest/user/identities/users/{userId}/password/reset
     */
    async resetPasswordForUser(userId: string): Promise<void> {
        await this.$execute<void>('PUT', '/identities/users/{userId}/password/reset', [
            { name: 'userId', value: userId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: PUT /api/rest/user/identities/users/{userId}/password/reset
     */
    resetPasswordForUserRequest(userId: string): RestClientRequest<void> {
        return this.$create<void>('PUT', '/identities/users/{userId}/password/reset', [
            { name: 'userId', value: userId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: POST /api/rest/user/identities/users/password_reset
     */
    async reset(resetPasswordRequest: ResetPasswordRequest): Promise<void> {
        await this.$execute<void>('POST', '/identities/users/password_reset', [
            {
                name: 'resetPasswordRequest',
                value: resetPasswordRequest,
                transport: 'BODY',
                typeName: 'ResetPasswordRequest',
            },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/user/identities/users/password_reset
     */
    resetRequest(resetPasswordRequest: ResetPasswordRequest): RestClientRequest<void> {
        return this.$create<void>('POST', '/identities/users/password_reset', [
            {
                name: 'resetPasswordRequest',
                value: resetPasswordRequest,
                transport: 'BODY',
                typeName: 'ResetPasswordRequest',
            },
        ]);
    }

    /**
     * HTTP: POST /api/rest/user/identities/users/activate
     */
    async activate(activateUserIdentityRequest: ActivateUserIdentityRequest): Promise<AccessTokenResponse | null> {
        const result = await this.$execute<AccessTokenResponse>('POST', '/identities/users/activate', [
            {
                name: 'activateUserIdentityRequest',
                value: activateUserIdentityRequest,
                transport: 'BODY',
                typeName: 'ActivateUserIdentityRequest',
            },
        ]);

        if (result === null) {
            return null;
        }
        return result as AccessTokenResponse;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/user/identities/users/activate
     */
    activateRequest(
        activateUserIdentityRequest: ActivateUserIdentityRequest
    ): RestClientRequest<AccessTokenResponse | null> {
        return this.$create<AccessTokenResponse>('POST', '/identities/users/activate', [
            {
                name: 'activateUserIdentityRequest',
                value: activateUserIdentityRequest,
                transport: 'BODY',
                typeName: 'ActivateUserIdentityRequest',
            },
        ]);
    }

    /**
     * HTTP: GET /api/rest/user/identities/users/registrations/{registrationId}
     */
    async getUserRegistration(registrationId: string): Promise<UserRegistrationDetails | null> {
        const result = await this.$execute<UserRegistrationDetails>(
            'GET',
            '/identities/users/registrations/{registrationId}',
            [{ name: 'registrationId', value: registrationId, transport: 'PATH', typeName: 'string' }]
        );

        if (result === null) {
            return null;
        }
        return result as UserRegistrationDetails;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/user/identities/users/registrations/{registrationId}
     */
    getUserRegistrationRequest(registrationId: string): RestClientRequest<UserRegistrationDetails | null> {
        return this.$create<UserRegistrationDetails>('GET', '/identities/users/registrations/{registrationId}', [
            { name: 'registrationId', value: registrationId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/user/identities/users/mfa/enabled
     */
    async isMfaEnabled(): Promise<boolean | null> {
        const result = await this.$execute<boolean>('GET', '/identities/users/mfa/enabled', []);

        if (result === null) {
            return null;
        }
        return result as boolean;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/user/identities/users/mfa/enabled
     */
    isMfaEnabledRequest(): RestClientRequest<boolean | null> {
        return this.$create<boolean>('GET', '/identities/users/mfa/enabled', []);
    }
}
