import React, { useMemo, useState } from 'react';
import { debounce, isArray, isEqual, last } from 'lodash';
import {
    FormContainer,
    FormAutocomplete,
    getInitialsForName,
    ToastType,
    showToasty,
    Tooltip,
    KapDialog,
    FormButtons,
} from '@kapeta/ui-web-components';
import { Box, Button, Avatar, Chip, Stack, Typography } from '@mui/material';
import MemberTableCell from '../../../../../components/MemberTableCell';
import api from '../../../../../service/APIService';
import { hasFullAccess } from '../OrganizationMembersPage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { UserToInvite, UnknownUser, KnownUser } from './InviteMemberDialog';
import { MemberIdentity } from '../../../../../../.generated/entities/MemberIdentity';

const isUnknownUser = (user: UserToInvite | string): user is UnknownUser => typeof user !== 'string' && 'email' in user;

const isKnownUser = (user: UserToInvite | string): user is KnownUser =>
    typeof user !== 'string' && 'inviteeIdentityId' in user;

type SelectUsersFormProps = {
    users: UserToInvite[];
    setUsers: React.Dispatch<React.SetStateAction<UserToInvite[]>>;
    scopes: string[];
    onClose: (didInviteUsers?: boolean) => void;
    organization?: MemberIdentity;
    changeToScopesMode: () => void;
};

export default function SelectUsersForm({
    organization,
    users,
    setUsers,
    scopes,
    onClose,
    changeToScopesMode,
}: SelectUsersFormProps) {
    const initialFormValue = useMemo(() => ({ users }), [users]);
    const hasUsers = users.length > 0;
    const hasInvalidUsers = users.some((user) => !user.valid);
    const accessSummary = hasFullAccess(scopes) ? 'Full access' : 'Limited access';

    /**
     * When the user type in the input field we search for existing users via the API which we can display in the
     * dropdown.
     */
    const [userOptions, setUserOptions] = useState<UserToInvite[]>([]);
    const searchUsers = useMemo(
        () =>
            debounce(async (searchTerm: string) => {
                if (!searchTerm) {
                    setUserOptions([]);
                    return;
                }

                const matchingUsers = await api
                    .identities('user')
                    .search(searchTerm)
                    .then((searchIdentities) =>
                        (searchIdentities ?? [])
                            .map((user) => {
                                return {
                                    label: user.name,
                                    inviteeIdentityId: user.id,
                                    handle: user.handle,
                                    valid: true,
                                } satisfies KnownUser;
                            })
                            .sort((a, b) => a.label.localeCompare(b.label))
                    );

                setUserOptions(matchingUsers);
            }, 250),
        []
    );

    /**
     * When the user submits for form we send the invites to the API.
     */
    const [isSending, setIsSending] = useState(false);
    const inviteUsers = async () => {
        if (!organization) {
            return;
        }

        setIsSending(true);
        try {
            const promises = users
                .filter((user) => user.valid)
                .map(async (user) =>
                    api
                        .organizations()
                        .invite(organization.identity.id, {
                            permissions: scopes,
                            ...(isKnownUser(user)
                                ? { inviteeIdentityId: user.inviteeIdentityId }
                                : { email: user.email }),
                        })
                        .then(() => {
                            showToasty({
                                type: ToastType.SUCCESS,
                                message: `Invite sent to ${user.label}`,
                                title: 'Success',
                            });
                        })
                        .catch(() => {
                            showToasty(
                                {
                                    type: ToastType.DANGER,
                                    message: `Failed to send invite to ${user.label}`,
                                    title: 'Error',
                                },
                                {
                                    autoClose: false,
                                }
                            );
                        })
                );

            await Promise.allSettled(promises);
        } finally {
            setIsSending(false);
        }

        onClose(true);
    };

    const canSubmit = organization && !isSending && hasUsers && !hasInvalidUsers;

    return (
        <FormContainer
            initialValue={initialFormValue}
            onChange={(data) => {
                if (!isEqual(data.users as UserToInvite[], users)) {
                    setUsers(data.users as UserToInvite[]);
                }
            }}
            onSubmit={inviteUsers}
        >
            <KapDialog.Title>Invite members</KapDialog.Title>

            <KapDialog.Content dividers sx={{ borderBottom: 'none', overflowY: 'auto' }}>
                <Stack spacing={3} direction="column" sx={{ py: 2 }}>
                    <Typography variant="body1">By email</Typography>
                    <FormAutocomplete
                        name="users"
                        placeholder="Add name, email or handle"
                        options={userOptions}
                        onChange={(_event, value) => {
                            if (isArray(value)) {
                                const lastValue = last(value);
                                const isString = typeof lastValue === 'string';
                                // If the last value is a string, we expect that the user has typed in an
                                // email address, and we replace the last value with an "unknown" user
                                if (isString) {
                                    const trimmedValue = lastValue.trim();

                                    // If we already have a user in the list with the same email address, we
                                    // will remove this last value.
                                    const isDuplicate = value.some((user) => {
                                        if (
                                            isUnknownUser(user) &&
                                            user.email.toLocaleLowerCase() === trimmedValue.toLocaleLowerCase()
                                        ) {
                                            return true;
                                        }
                                        return false;
                                    });
                                    if (isDuplicate) {
                                        value.pop();
                                        return;
                                    }

                                    // Replace the last value with an "unknown" user.
                                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                    value[value.length - 1] = {
                                        label: trimmedValue,
                                        email: trimmedValue,
                                        valid: emailRegex.test(trimmedValue),
                                    };
                                }
                            }
                        }}
                        onInputChange={(_event, value) => {
                            searchUsers(value)?.catch(() => null);
                        }}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            return option.label;
                        }}
                        filterOptions={(options) => {
                            // We do not want to filter the options because the API returns the users
                            // already filtered.
                            return options;
                        }}
                        isOptionEqualToValue={(option, value) => isEqual(option, value)}
                        autoSelect
                        autoHighlight
                        multiple
                        freeSolo
                        hidePopupIndicator
                        filterSelectedOptions
                        renderOption={(props, option) => (
                            <li {...props}>
                                <MemberTableCell
                                    name={option.label}
                                    handle={isKnownUser(option) ? option.handle : ''}
                                />
                            </li>
                        )}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => {
                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <Chip
                                        avatar={
                                            isKnownUser(option) && option.valid ? (
                                                <Avatar alt={option.label}>{getInitialsForName(option.label)}</Avatar>
                                            ) : undefined
                                        }
                                        label={option.label}
                                        color={option.valid ? 'default' : 'error'}
                                        size="medium"
                                        {...getTagProps({ index })}
                                    />
                                );
                            })
                        }
                        noOptionsText="No users found"
                    />

                    <Box
                        sx={{
                            p: 2,
                            bgcolor: 'primary.states.focus',
                            borderRadius: 2.5,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: 1,
                            cursor: 'pointer',
                        }}
                        onClick={changeToScopesMode}
                    >
                        <Typography variant="subtitle2">
                            Invited members will have{' '}
                            <Box component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
                                {accessSummary}
                            </Box>
                        </Typography>
                        <Tooltip
                            title="A permission is the ability to perform a specific action. For example, the ability to delete an issue is a permission. With Full access the invited member will be able to perform all actions."
                            placement="top"
                            arrow
                            maxWidth={145}
                        >
                            <InfoOutlinedIcon sx={{ color: 'action.active' }} />
                        </Tooltip>
                    </Box>
                </Stack>
            </KapDialog.Content>

            <KapDialog.Actions>
                <FormButtons addWrapperDiv={false}>
                    <Button onClick={() => onClose()}>Cancel</Button>
                    <Button type="submit" variant="contained" disabled={!canSubmit}>
                        Send invite
                    </Button>
                </FormButtons>
            </KapDialog.Actions>
        </FormContainer>
    );
}
