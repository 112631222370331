//
// GENERATED SOURCE - DO NOT EDIT
//

import { useMemo } from 'react';
import { RestClient } from '@kapeta/sdk-web-rest-client';
import { RestClientRequest } from '@kapeta/sdk-rest';
import { ThresholdPayload } from '../../../.generated/entities/ThresholdPayload';
import { CreatePaymentResponse } from '../../../.generated/entities/CreatePaymentResponse';
import { PaymentMethod } from '../../../.generated/entities/PaymentMethod';

export type ConfigurePaymentsClient = (client: PaymentsClient) => PaymentsClient;

/**
 * Creates a new PaymentsClient for React components.
 * The client is configured with the provided configuration function.
 *
 * It is wrapped in a useMemo hook to ensure that the client is only created once.
 */
export const usePaymentsClient = (configure?: ConfigurePaymentsClient): PaymentsClient => {
    return useMemo(() => {
        const client = new PaymentsClient();
        if (configure) {
            return configure(client);
        }
        return client;
    }, [configure]);
};

/**
 * A client for the Payments API.
 * Use the usePaymentsClient hook to create a client in React.
 *
 * Use the PaymentsClient directly in other contexts.
 */
export class PaymentsClient extends RestClient {
    constructor() {
        super('api/rest/payments');
    }

    /**
     * HTTP: GET /api/rest/payments/account/{handle}/spend-threshold
     */
    async getSpendThreshold(handle: string): Promise<ThresholdPayload | null> {
        const result = await this.$execute<ThresholdPayload>('GET', '/account/{handle}/spend-threshold', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as ThresholdPayload;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/payments/account/{handle}/spend-threshold
     */
    getSpendThresholdRequest(handle: string): RestClientRequest<ThresholdPayload | null> {
        return this.$create<ThresholdPayload>('GET', '/account/{handle}/spend-threshold', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: PUT /api/rest/payments/account/{handle}/spend-threshold
     */
    async setSpendThreshold(handle: string, payload: ThresholdPayload): Promise<void> {
        await this.$execute<void>('PUT', '/account/{handle}/spend-threshold', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
            { name: 'payload', value: payload, transport: 'BODY', typeName: 'ThresholdPayload' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: PUT /api/rest/payments/account/{handle}/spend-threshold
     */
    setSpendThresholdRequest(handle: string, payload: ThresholdPayload): RestClientRequest<void> {
        return this.$create<void>('PUT', '/account/{handle}/spend-threshold', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
            { name: 'payload', value: payload, transport: 'BODY', typeName: 'ThresholdPayload' },
        ]);
    }

    /**
     * HTTP: POST /api/rest/payments/account/{handle}/payments/methods
     */
    async createPaymentMethod(handle: string): Promise<CreatePaymentResponse | null> {
        const result = await this.$execute<CreatePaymentResponse>('POST', '/account/{handle}/payments/methods', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as CreatePaymentResponse;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/payments/account/{handle}/payments/methods
     */
    createPaymentMethodRequest(handle: string): RestClientRequest<CreatePaymentResponse | null> {
        return this.$create<CreatePaymentResponse>('POST', '/account/{handle}/payments/methods', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: POST /api/rest/payments/account/{handle}/payments/methods/attach/{setupIntentId}
     */
    async attachPaymentMethod(handle: string, setupIntentId: string): Promise<void> {
        await this.$execute<void>('POST', '/account/{handle}/payments/methods/attach/{setupIntentId}', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
            { name: 'setupIntentId', value: setupIntentId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/payments/account/{handle}/payments/methods/attach/{setupIntentId}
     */
    attachPaymentMethodRequest(handle: string, setupIntentId: string): RestClientRequest<void> {
        return this.$create<void>('POST', '/account/{handle}/payments/methods/attach/{setupIntentId}', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
            { name: 'setupIntentId', value: setupIntentId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/payments/account/{handle}/payments/methods
     */
    async getPaymentMethods(handle: string): Promise<PaymentMethod[] | null> {
        const result = await this.$execute<PaymentMethod[]>('GET', '/account/{handle}/payments/methods', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as PaymentMethod[];
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/payments/account/{handle}/payments/methods
     */
    getPaymentMethodsRequest(handle: string): RestClientRequest<PaymentMethod[] | null> {
        return this.$create<PaymentMethod[]>('GET', '/account/{handle}/payments/methods', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: PATCH /api/rest/payments/account/{handle}/payments/methods/{paymentMethodId}/set-default
     */
    async setDefaultPaymentMethod(handle: string, paymentMethodId: string): Promise<void> {
        await this.$execute<void>('PATCH', '/account/{handle}/payments/methods/{paymentMethodId}/set-default', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
            { name: 'paymentMethodId', value: paymentMethodId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: PATCH /api/rest/payments/account/{handle}/payments/methods/{paymentMethodId}/set-default
     */
    setDefaultPaymentMethodRequest(handle: string, paymentMethodId: string): RestClientRequest<void> {
        return this.$create<void>('PATCH', '/account/{handle}/payments/methods/{paymentMethodId}/set-default', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
            { name: 'paymentMethodId', value: paymentMethodId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: DELETE /api/rest/payments/account/{handle}/payments/methods/{paymentMethodId}
     */
    async deletePaymentMethod(handle: string, paymentMethodId: string): Promise<void> {
        await this.$execute<void>('DELETE', '/account/{handle}/payments/methods/{paymentMethodId}', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
            { name: 'paymentMethodId', value: paymentMethodId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: DELETE /api/rest/payments/account/{handle}/payments/methods/{paymentMethodId}
     */
    deletePaymentMethodRequest(handle: string, paymentMethodId: string): RestClientRequest<void> {
        return this.$create<void>('DELETE', '/account/{handle}/payments/methods/{paymentMethodId}', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
            { name: 'paymentMethodId', value: paymentMethodId, transport: 'PATH', typeName: 'string' },
        ]);
    }
}
