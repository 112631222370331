//
// GENERATED SOURCE - DO NOT EDIT
//

import { useMemo } from 'react';
import { RestClient } from '@kapeta/sdk-web-rest-client';
import { RestClientRequest } from '@kapeta/sdk-rest';
import { ExtendedIdentity } from '../../../.generated/entities/ExtendedIdentity';
import { Identity } from '../../../.generated/entities/Identity';
import { TypedRequest } from '../../../.generated/entities/TypedRequest';
import { MemberIdentity } from '../../../.generated/entities/MemberIdentity';
import { JWTAccessContext } from '../../../.generated/entities/JWTAccessContext';
import { SliceSearchIdentity } from '../../../.generated/entities/SliceSearchIdentity';
import { SearchType } from '../../../.generated/entities/SearchType';
import { Pageable } from '@kapeta/sdk-rest';
import { PublicIdentity } from '../../../.generated/entities/PublicIdentity';

export type ConfigureIdentityClient = (client: IdentityClient) => IdentityClient;

/**
 * Creates a new IdentityClient for React components.
 * The client is configured with the provided configuration function.
 *
 * It is wrapped in a useMemo hook to ensure that the client is only created once.
 */
export const useIdentityClient = (configure?: ConfigureIdentityClient): IdentityClient => {
    return useMemo(() => {
        const client = new IdentityClient();
        if (configure) {
            return configure(client);
        }
        return client;
    }, [configure]);
};

/**
 * A client for the Identity API.
 * Use the useIdentityClient hook to create a client in React.
 *
 * Use the IdentityClient directly in other contexts.
 */
export class IdentityClient extends RestClient {
    constructor() {
        super('api/rest/identity');
    }

    /**
     * HTTP: GET /api/rest/identity/identities/{identityId}
     */
    async get(identityId: string): Promise<ExtendedIdentity | null> {
        const result = await this.$execute<ExtendedIdentity>('GET', '/identities/{identityId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as ExtendedIdentity;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identity/identities/{identityId}
     */
    getRequest(identityId: string): RestClientRequest<ExtendedIdentity | null> {
        return this.$create<ExtendedIdentity>('GET', '/identities/{identityId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: PUT /api/rest/identity/identities/{identityId}
     */
    async update(identityId: string, identity: Identity): Promise<Identity | null> {
        const result = await this.$execute<Identity>('PUT', '/identities/{identityId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'identity', value: identity, transport: 'BODY', typeName: 'Identity' },
        ]);

        if (result === null) {
            return null;
        }
        return result as Identity;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: PUT /api/rest/identity/identities/{identityId}
     */
    updateRequest(identityId: string, identity: Identity): RestClientRequest<Identity | null> {
        return this.$create<Identity>('PUT', '/identities/{identityId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'identity', value: identity, transport: 'BODY', typeName: 'Identity' },
        ]);
    }

    /**
     * HTTP: DELETE /api/rest/identity/identities/{identityId}
     */
    async delete(identityId: string): Promise<void> {
        await this.$execute<void>('DELETE', '/identities/{identityId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: DELETE /api/rest/identity/identities/{identityId}
     */
    deleteRequest(identityId: string): RestClientRequest<void> {
        return this.$create<void>('DELETE', '/identities/{identityId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: POST /api/rest/identity/identities/{identityId}/members/{memberId}
     */
    async addMember(identityId: string, memberId: string, scope: string[]): Promise<void> {
        await this.$execute<void>('POST', '/identities/{identityId}/members/{memberId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'memberId', value: memberId, transport: 'PATH', typeName: 'string' },
            { name: 'scope', value: scope, transport: 'QUERY', typeName: 'string[]' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/identity/identities/{identityId}/members/{memberId}
     */
    addMemberRequest(identityId: string, memberId: string, scope: string[]): RestClientRequest<void> {
        return this.$create<void>('POST', '/identities/{identityId}/members/{memberId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'memberId', value: memberId, transport: 'PATH', typeName: 'string' },
            { name: 'scope', value: scope, transport: 'QUERY', typeName: 'string[]' },
        ]);
    }

    /**
     * HTTP: DELETE /api/rest/identity/identities/{identityId}/members/{memberId}
     */
    async removeMember(identityId: string, memberId: string): Promise<void> {
        await this.$execute<void>('DELETE', '/identities/{identityId}/members/{memberId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'memberId', value: memberId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: DELETE /api/rest/identity/identities/{identityId}/members/{memberId}
     */
    removeMemberRequest(identityId: string, memberId: string): RestClientRequest<void> {
        return this.$create<void>('DELETE', '/identities/{identityId}/members/{memberId}', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'memberId', value: memberId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: POST /api/rest/identity/identities/register
     */
    async register(typedRequest: TypedRequest): Promise<void> {
        await this.$execute<void>('POST', '/identities/register', [
            { name: 'typedRequest', value: typedRequest, transport: 'BODY', typeName: 'TypedRequest' },
        ]);
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: POST /api/rest/identity/identities/register
     */
    registerRequest(typedRequest: TypedRequest): RestClientRequest<void> {
        return this.$create<void>('POST', '/identities/register', [
            { name: 'typedRequest', value: typedRequest, transport: 'BODY', typeName: 'TypedRequest' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identity/identities/{identityId}/memberships
     */
    async listMemberships(identityId: string, type: string): Promise<MemberIdentity[] | null> {
        const result = await this.$execute<MemberIdentity[]>('GET', '/identities/{identityId}/memberships', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'type', value: type, transport: 'QUERY', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as MemberIdentity[];
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identity/identities/{identityId}/memberships
     */
    listMembershipsRequest(identityId: string, type: string): RestClientRequest<MemberIdentity[] | null> {
        return this.$create<MemberIdentity[]>('GET', '/identities/{identityId}/memberships', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'type', value: type, transport: 'QUERY', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identity/identities/{identityId}/members
     */
    async listMembers(identityId: string, type: string): Promise<MemberIdentity[] | null> {
        const result = await this.$execute<MemberIdentity[]>('GET', '/identities/{identityId}/members', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'type', value: type, transport: 'QUERY', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as MemberIdentity[];
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identity/identities/{identityId}/members
     */
    listMembersRequest(identityId: string, type: string): RestClientRequest<MemberIdentity[] | null> {
        return this.$create<MemberIdentity[]>('GET', '/identities/{identityId}/members', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
            { name: 'type', value: type, transport: 'QUERY', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identity/identities/{identityId}/contexts
     */
    async contextsForIdentity(identityId: string): Promise<JWTAccessContext[] | null> {
        const result = await this.$execute<JWTAccessContext[]>('GET', '/identities/{identityId}/contexts', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as JWTAccessContext[];
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identity/identities/{identityId}/contexts
     */
    contextsForIdentityRequest(identityId: string): RestClientRequest<JWTAccessContext[] | null> {
        return this.$create<JWTAccessContext[]>('GET', '/identities/{identityId}/contexts', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identity/identities/{identityId}/as-member
     */
    async getAsMember(identityId: string): Promise<MemberIdentity | null> {
        const result = await this.$execute<MemberIdentity>('GET', '/identities/{identityId}/as-member', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as MemberIdentity;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identity/identities/{identityId}/as-member
     */
    getAsMemberRequest(identityId: string): RestClientRequest<MemberIdentity | null> {
        return this.$create<MemberIdentity>('GET', '/identities/{identityId}/as-member', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identity/identities/search
     */
    async searchFor(query: string, type: SearchType, pageable: Pageable): Promise<SliceSearchIdentity | null> {
        const result = await this.$execute<SliceSearchIdentity>('GET', '/identities/search', [
            { name: 'query', value: query, transport: 'QUERY', typeName: 'string' },
            { name: 'type', value: type, transport: 'QUERY', typeName: 'SearchType' },
            { name: 'pageable', value: pageable, transport: 'QUERY', typeName: 'Pageable' },
        ]);

        if (result === null) {
            return null;
        }
        return result as SliceSearchIdentity;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identity/identities/search
     */
    searchForRequest(
        query: string,
        type: SearchType,
        pageable: Pageable
    ): RestClientRequest<SliceSearchIdentity | null> {
        return this.$create<SliceSearchIdentity>('GET', '/identities/search', [
            { name: 'query', value: query, transport: 'QUERY', typeName: 'string' },
            { name: 'type', value: type, transport: 'QUERY', typeName: 'SearchType' },
            { name: 'pageable', value: pageable, transport: 'QUERY', typeName: 'Pageable' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identity/identities/by-id/{identityId}/public
     */
    async getBriefById(identityId: string): Promise<PublicIdentity | null> {
        const result = await this.$execute<PublicIdentity>('GET', '/identities/by-id/{identityId}/public', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as PublicIdentity;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identity/identities/by-id/{identityId}/public
     */
    getBriefByIdRequest(identityId: string): RestClientRequest<PublicIdentity | null> {
        return this.$create<PublicIdentity>('GET', '/identities/by-id/{identityId}/public', [
            { name: 'identityId', value: identityId, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identity/identities/by-handle/{handle}
     */
    async getByHandle(handle: string): Promise<ExtendedIdentity | null> {
        const result = await this.$execute<ExtendedIdentity>('GET', '/identities/by-handle/{handle}', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as ExtendedIdentity;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identity/identities/by-handle/{handle}
     */
    getByHandleRequest(handle: string): RestClientRequest<ExtendedIdentity | null> {
        return this.$create<ExtendedIdentity>('GET', '/identities/by-handle/{handle}', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identity/identities/by-handle/{handle}/public
     */
    async getBriefByHandle(handle: string): Promise<PublicIdentity | null> {
        const result = await this.$execute<PublicIdentity>('GET', '/identities/by-handle/{handle}/public', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as PublicIdentity;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identity/identities/by-handle/{handle}/public
     */
    getBriefByHandleRequest(handle: string): RestClientRequest<PublicIdentity | null> {
        return this.$create<PublicIdentity>('GET', '/identities/by-handle/{handle}/public', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);
    }

    /**
     * HTTP: GET /api/rest/identity/identities/by-handle/{handle}/as-member
     */
    async getByHandleAsMember(handle: string): Promise<MemberIdentity | null> {
        const result = await this.$execute<MemberIdentity>('GET', '/identities/by-handle/{handle}/as-member', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);

        if (result === null) {
            return null;
        }
        return result as MemberIdentity;
    }

    /**
     * Throws if service responds with a status code higher than 399 and not 404.
     * For 404 responses, null is returned.
     *
     * HTTP: GET /api/rest/identity/identities/by-handle/{handle}/as-member
     */
    getByHandleAsMemberRequest(handle: string): RestClientRequest<MemberIdentity | null> {
        return this.$create<MemberIdentity>('GET', '/identities/by-handle/{handle}/as-member', [
            { name: 'handle', value: handle, transport: 'PATH', typeName: 'string' },
        ]);
    }
}
