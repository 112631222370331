import React, { useMemo } from 'react';
import { InlineEditFormField } from './InlineEditFormField';
import { BoxProps } from '@mui/material';
import { showToasty, ToastType } from '@kapeta/ui-web-components';
import api from '../service/APIService';
import { ExtendedIdentity } from '../../.generated/entities/ExtendedIdentity';

type EditProfileNameFormProps = {
    identity: ExtendedIdentity | undefined;
    onNameChange?: (newName: string) => void;
} & BoxProps;

export const EditProfileNameForm = ({ identity, onNameChange, ...boxProps }: EditProfileNameFormProps) => {
    const initialValue = useMemo(
        () => ({
            name: identity?.name || '',
        }),
        [identity?.name]
    );

    const updateName = async (newName: string) => {
        if (!newName || !identity) {
            return;
        }

        try {
            await api.profile().updateUser({ id: identity.id, name: newName.trim() });
            showToasty({
                type: ToastType.SUCCESS,
                message: 'Profile was updated',
                title: 'Success',
            });
            onNameChange?.(newName);
        } catch (error) {
            // ignore
        }
    };

    return (
        <InlineEditFormField
            {...boxProps}
            formContainerProps={{
                initialValue,
                onSubmitData: (data:any) => updateName(data.name as string),
            }}
            formFieldProps={{
                label: 'Full name',
                name: 'name',
                validation: ['required'],
            }}
        />
    );
};
