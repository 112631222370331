//
// GENERATED SOURCE - DO NOT EDIT
//

export enum ClientType {
    NATIVE = 'NATIVE',
    BROWSER = 'BROWSER',
    SERVER = 'SERVER',
    MACHINE_TO_MACHINE = 'MACHINE_TO_MACHINE',
}
