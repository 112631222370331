import React, { useState } from 'react';
import { FormContainer, FormField, FormButtons, FormFieldType, ToastType, showToasty } from '@kapeta/ui-web-components';
import { Box, BoxProps, Button, CircularProgress, Stack, TextField, Typography, useTheme } from '@mui/material';
import api from '../service/APIService';

type EditPasswordFormProps = {
    onPasswordChange?: () => void;
    identityId: string;
} & BoxProps;

const ignore = () => null;

export const EditPasswordForm = ({ onPasswordChange, identityId, sx, ...otherBoxProps }: EditPasswordFormProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isResettingPassword, setIsResettingPassword] = useState(false);

    const resetPassword = async () => {
        try {
            setIsResettingPassword(true);
            await api.profile().resetPassword(identityId);
            showToasty({
                type: ToastType.SUCCESS,
                message: 'Password was reset. Check your inbox',
                title: 'Success',
            });
            onPasswordChange?.();
        } catch (error) {
            // ignore
        } finally {
            setIsResettingPassword(false);
        }
    };

    const updatePassword = async (oldPassword: string, newPassword: string) => {
        try {
            await api.profile().updatePassword(identityId, oldPassword, newPassword);
            showToasty({
                type: ToastType.SUCCESS,
                message: 'Password was updated',
                title: 'Success',
            });
            onPasswordChange?.();
        } catch (error) {
            // ignore
        } finally {
            setIsEditing(false);
        }
    };

    const { spacing, palette } = useTheme();

    return isEditing ? (
        <Box
            sx={{
                ...sx,
                '.form-container': { backgroundColor: 'unset' }, // Remove the background color from form-container
                position: 'relative',
                ...(isEditing
                    ? {
                          '&::before': {
                              content: '""',
                              position: 'absolute',
                              top: spacing(-1.5),
                              bottom: spacing(-1.5),
                              left: spacing(-1),
                              right: spacing(-1),
                              borderRadius: '6px',
                              backgroundColor: palette.grey[50],
                              pointerEvents: 'none',
                              zIndex: -1,
                          },
                      }
                    : {}),
            }}
            {...otherBoxProps}
        >
            <FormContainer
                onReset={() => setIsEditing(false)}
                onSubmitData={(data) => updatePassword(data.oldPassword as string, data.newPassword as string)}
            >
                <Stack direction={'column'} gap={1.5}>
                    <Stack
                        direction={'row'}
                        gap={1.5}
                        flex={1}
                        sx={{
                            '>.MuiBox-root': { flex: 1 },
                        }}
                    >
                        <FormField
                            name="oldPassword"
                            type={FormFieldType.PASSWORD}
                            label="Current password"
                            variant="outlined"
                            validation={['required']}
                        />

                        <FormField
                            name="newPassword"
                            type={FormFieldType.PASSWORD}
                            label="New password"
                            variant="outlined"
                            validation={['required']}
                        />
                    </Stack>

                    <Stack direction="row" gap={2}>
                        <Typography variant="body2" sx={{ flex: 1 }}>
                            {"Can't remember your current password?"}
                            <br />
                            <Box
                                component="span"
                                sx={{
                                    color: 'primary.main',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    resetPassword().catch(ignore);
                                }}
                            >
                                Reset your password via email
                                {isResettingPassword && <CircularProgress size={12} color="inherit" sx={{ ml: 1 }} />}
                            </Box>
                        </Typography>
                        <Box sx={{ flex: 1 }}>
                            <FormButtons sx={{ pt: 0 }}>
                                <Button type="reset" variant="text" color="inherit" size="small">
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" size="small">
                                    Save password
                                </Button>
                            </FormButtons>
                        </Box>
                    </Stack>
                </Stack>
            </FormContainer>
        </Box>
    ) : (
        <Stack direction="row" gap={2} alignItems="center">
            <TextField type="password" label="Password" value="abcdefghijklmnop" disabled />
            <Button variant="text" color="primary" size="small" onClick={() => setIsEditing(true)}>
                Change password
            </Button>
        </Stack>
    );
};
