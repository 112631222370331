import React, { useState } from 'react';
import { ToastType, showToasty, useConfirm } from '@kapeta/ui-web-components';
import api from '../service/APIService';
import { IconButton, CircularProgress, ListItemIcon, Menu, MenuItem, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { ExtendedIdentity } from '../../.generated/entities/ExtendedIdentity';
import { IdentityIdentifier } from '../../.generated/entities/IdentityIdentifier';

const EMAIL = 'email';
const PHONE = 'phone';

type EditIdentifierButtonProps = {
    identity: ExtendedIdentity;
    identifier: IdentityIdentifier;
    canRemove?: boolean;
    onRemove?: () => void;
    onPrimaryChange?: () => void;
};

const ignore = () => undefined;

export const EditIdentifierButton = ({
    identity,
    identifier,
    canRemove = true,
    onRemove,
    onPrimaryChange,
}: EditIdentifierButtonProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);

    const closeMenu = () => {
        setAnchorEl(null);
    };
    const confirm = useConfirm();

    const buttonId = `more-button-${identifier.id}`;
    const menuId = `edit-identifier-menu-${identifier.id}`;

    if ([EMAIL, PHONE].includes(identifier.type) === false) {
        throw new Error(`Unknown identifier type ${identifier.type}`);
    }

    const [isUpdatingPrimary, setIsUpdatingPrimary] = useState(false);
    const makePrimary = async () => {
        try {
            setIsUpdatingPrimary(true);

            await api.identifiers().setAsPrimary(identity.id, identifier.id);

            showToasty({
                title: 'Success',
                message: `${identifier.identifier} is set as primary ${
                    identifier.type === 'email' ? 'e-mail' : 'phone number'
                }`,
                type: ToastType.SUCCESS,
            });

            onPrimaryChange?.();
        } catch (error) {
            // ignore
        } finally {
            setIsUpdatingPrimary(false);
        }
    };

    const [isDeleting, setIsDeleting] = useState(false);
    async function deleteIdentifier() {
        if (
            await confirm({
                title: `Delete ${identifier.type === 'email' ? 'email' : 'phone number'}`,
                content: `Are you sure you want to delete this ${
                    identifier.type === 'email' ? 'e-mail' : 'phone number'
                }?`,
                confirmationText: 'Yes, delete',
                confirmationButtonProps: { color: 'error' },
                cancellationText: 'No, cancel',
            })
        ) {
            try {
                setIsDeleting(true);

                await api.identifiers().remove(identity.id, identifier.id);

                showToasty({
                    title: 'Success',
                    message: `${identifier.type === 'email' ? 'Email' : 'Phone number'} deleted`,
                    type: ToastType.SUCCESS,
                });

                onRemove?.();
            } catch (error) {
                // ignore
            } finally {
                setIsDeleting(false);
            }
        }
    }

    const showMakePrimary = identifier.verified && !identifier.primary;
    const showRemove = canRemove && !isDeleting && !isUpdatingPrimary;
    const showMenu = showMakePrimary || showRemove;

    return showMenu ? (
        <>
            <IconButton
                aria-label="More"
                id={buttonId}
                aria-controls={isOpen ? menuId : undefined}
                aria-expanded={isOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                disabled={isDeleting}
            >
                {isDeleting || isUpdatingPrimary ? <CircularProgress size="24" color="inherit" /> : <MoreHorizIcon />}
            </IconButton>
            <Menu
                id={menuId}
                MenuListProps={{ 'aria-labelledby': buttonId }}
                anchorEl={anchorEl}
                open={isOpen}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {showMakePrimary && (
                    <MenuItem
                        onClick={() => {
                            makePrimary().catch(ignore);
                            closeMenu();
                        }}
                        dense
                    >
                        <ListItemIcon>
                            {identifier.type === 'email' ? (
                                <MailOutlineIcon fontSize="small" />
                            ) : (
                                <SmartphoneIcon fontSize="small" />
                            )}
                        </ListItemIcon>
                        <ListItemText>Make primary</ListItemText>
                    </MenuItem>
                )}

                {showRemove && (
                    <MenuItem
                        onClick={() => {
                            deleteIdentifier().catch(ignore);
                            closeMenu();
                        }}
                        dense
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete {identifier.type === 'email' ? 'e-mail' : 'phone number'}</ListItemText>
                    </MenuItem>
                )}
            </Menu>
        </>
    ) : null;
};
