import React from 'react';
import { SimpleLoader } from '@kapeta/ui-web-components';
import { emitContextChange } from '@kapeta/web-microfrontend/browser';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { TabPage } from '../../../layouts/TabPage';
import { Alert, Stack, Typography } from '@mui/material';
import { EditHandleForm } from '../../../components/EditHandleForm';
import { useProfileSettings } from '../../../hooks/useProfileSettings';
import { EditProfileNameForm } from '../../../components/EditProfileNameForm';
import { EditIdentifiersForm } from '../../../components/EditIdentifiersForm';
import { EditPasswordForm } from '../../../components/EditPasswordForm';
import { SystemAdminForms } from '../../../components/SystemAdminForms';

export const ProfileSettingsPage = () => {
    const { profile, reloadProfile, loading, identifiers, reloadIdentifiers } = useProfileSettings();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const onHandleChange = (newHandle: string) => {
        if (!profile) {
            return;
        }
        navigate(generatePath(pathname.replace(profile.handle, ':handle'), { handle: newHandle }), {
            replace: true,
        });
    };

    const showPhoneNumbers = window.localStorage.getItem('showPhoneNumbers') === 'true';

    return (
        <TabPage
            title="Personal account"
            introduction={'Configure your profile settings'}
            sx={{
                '& .MuiFormControl-root': { my: 0 }, // Remove hardcoded margins from all form controls
            }}
        >
            <SimpleLoader loading={loading}>
                <Stack direction="column" gap={3}>
                    <Stack direction="column" gap={2}>
                        <Typography variant="body1" component="h3">
                            Profile
                        </Typography>

                        <Stack direction="row" gap={3} alignItems={'flex-start'}>
                            <EditProfileNameForm
                                flex={1}
                                identity={profile}
                                onNameChange={() => {
                                    reloadProfile();
                                    emitContextChange();
                                }}
                            />
                            {profile?.id && (
                                <EditHandleForm
                                    // Disabled until we figure out how to handle renames on the backend
                                    // https://linear.app/kapeta/issue/KAP-1201/[bug]-changing-handle-breaks-links-in-blockhub
                                    disabled
                                    flex={1}
                                    identityId={profile?.id}
                                    handleIdentifier={identifiers?.handle}
                                    onHandleChange={(newHandle) => {
                                        reloadIdentifiers();
                                        onHandleChange?.(newHandle);
                                        emitContextChange();
                                    }}
                                />
                            )}
                        </Stack>
                    </Stack>

                    <Stack direction="column" gap={2}>
                        <Typography variant="body1" component="h3">
                            E-mail
                        </Typography>

                        <EditIdentifiersForm
                            identity={profile}
                            identifierType="email"
                            identifiers={identifiers.emails}
                            flex={1}
                            onIdentifierChange={reloadIdentifiers}
                        />
                    </Stack>

                    {showPhoneNumbers && (
                        <Stack direction="column" gap={2}>
                            <Typography variant="body1" component="h3">
                                Phone number
                            </Typography>

                            <EditIdentifiersForm
                                identity={profile}
                                identifierType="phone"
                                identifiers={identifiers.phones}
                                flex={1}
                                onIdentifierChange={reloadIdentifiers}
                            />
                        </Stack>
                    )}

                    {profile?.id && (
                        <Stack direction="column" gap={2}>
                            <Typography variant="body1" component="h3">
                                Password
                            </Typography>

                            <EditPasswordForm identityId={profile.id} />
                        </Stack>
                    )}

                    {profile?.id && (
                        <SystemAdminForms handle={profile.handle} identityType={'user'} identityId={profile.id} />
                    )}

                    <Alert severity="warning" sx={{ mt: 3 }}>
                        <strong>Delete account</strong>
                        <br />
                        Contact support if you want to delete your account.
                    </Alert>
                </Stack>
            </SimpleLoader>
        </TabPage>
    );
};
